// paths

//$images : '../../images/';
$images : '../img/';

// Global variables

$black : #17181c;
$blue : #4071ff;
$gold : #efba67;
$orange : #ff9540;
$green : #3bae3b;
$red: #f53737;

$color-text-grey : #dadad9;
$color-border : #cccccc;
$color-text-grey : #888888;

// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:400,700&subset=latin-ext');

@font-face {
    font-family: "Cardo";
    src: url("../fonts/CARDO-REGULAR_0.TTF");
}
@font-face {
    font-family: "Cardo";
    src: url("../fonts/CARDO-BOLD_0.TTF");
    font-weight: bold;
}
@font-face {
    font-family: "Cardo";
    src: url("../fonts/CARDO-ITALIC.TTF");
    font-style: italic;
}


// Media mixins

@mixin small() {
    @media only screen and (max-width: 767px) {
        @content;
    }
}
@mixin notsmall() {
    @media only screen and (min-width: 768px) {
        @content;
    }
}



@mixin medium() {
    @media only screen and (max-width: 980px) {
        @content;
    }
}

@mixin onlymedium() {
    @media only screen and (min-width: 768px) and (max-width: 980px) {
        @content;
    }
}

@mixin onlybig() {
    @media only screen and (min-width: 980px) {
        @content;
    }
}

