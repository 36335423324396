@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Lato:400,700&subset=latin-ext");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: "Cardo";
  src: url("../fonts/CARDO-REGULAR_0.TTF"); }

@font-face {
  font-family: "Cardo";
  src: url("../fonts/CARDO-BOLD_0.TTF");
  font-weight: bold; }

@font-face {
  font-family: "Cardo";
  src: url("../fonts/CARDO-ITALIC.TTF");
  font-style: italic; }

/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.container {
  max-width: 1230px; }

.container-full {
  max-width: inherit;
  margin: 0 auto;
  width: 100%; }

.row-eq-height > [class^=col] {
  display: flex;
  flex-direction: column; }

body {
  margin: 0;
  padding: 0;
  font-family: Cardo, sans-serif; }

.main {
  padding-top: 136px; }
  @media only screen and (max-width: 767px) {
    .main {
      padding-top: 68px; } }

@media only screen and (max-width: 767px) {
  .no-logged .main {
    padding-top: 120px; } }

input, select, textarea, button {
  font-family: Cardo, sans-serif; }

a {
  color: inherit; }

.link {
  color: #4071ff; }

.link-green {
  color: #3bae3b; }

.link-red {
  color: #f53737; }

p {
  font-size: 18px;
  line-height: 24px;
  margin: 12px 0; }

a {
  text-decoration: none; }

h2 {
  font-weight: bold;
  font-size: 30px; }

h3 {
  margin: 30px 0 10px;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px; }

h4 {
  font-size: 20px;
  font-weight: bold; }

h5 {
  font-weight: bold;
  font-size: 18px;
  line-height: 28px; }

h6 {
  font-weight: bold;
  font-size: 16px; }

.heading {
  font-size: 30px;
  font-weight: bold;
  margin-top: 0; }

.button {
  display: block;
  padding: 16.5px 20px;
  text-align: center;
  width: 100%;
  transition: .5s; }
  .button:hover {
    cursor: pointer;
    text-decoration: none; }

.button-outline, #dolacz-do-spolecznosci input, #dolacz-do-spolecznosci .button {
  display: block;
  border: 1px solid #999999;
  padding: 16.5px 20px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  transition: .5s; }
  .button-outline:hover, #dolacz-do-spolecznosci input:hover, #dolacz-do-spolecznosci .button:hover {
    cursor: pointer;
    text-decoration: none; }

.button-green {
  background: #3bae3b;
  color: #fff !important;
  border: none;
  font-weight: bold; }

.button-blue {
  background: #4071ff;
  color: #fff;
  border: none;
  font-weight: bold; }
  .button-blue:hover {
    opacity: .8; }

.button-black {
  background: black;
  color: #fff;
  border: none;
  font-weight: bold; }
  .button-black:hover {
    opacity: .8; }

.green-badge {
  font-size: 11px;
  text-transform: uppercase;
  background: #3bae3b;
  color: white;
  padding: 5px 10px 3px;
  display: inline;
  line-height: 0;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  margin-left: 5px; }

.rounded-circle {
  border-radius: 50%; }

.bg-grey {
  background: #eee; }

.bg-white {
  background: #fff; }

.color-red {
  color: #f53737; }

.color-green {
  color: #3bae3b; }

.color-blue {
  color: #4071ff; }

.color-grey {
  color: #7d7d7d; }

.text-regular {
  font-weight: normal; }

b, .text-bold {
  font-weight: bold; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-bold {
  font-weight: bold; }

.emptyspace10 {
  padding-bottom: 10px; }

.emptyspace20 {
  padding-bottom: 20px; }

.emptyspace30 {
  padding-bottom: 30px; }

.emptyspace40 {
  padding-bottom: 40px; }

.emptyspace50 {
  padding-bottom: 50px; }

/* ELEMENTS */
.green-underscore {
  position: relative; }
  .green-underscore::after {
    position: absolute;
    left: 0;
    bottom: -10px;
    content: "";
    background: #3bae3b;
    width: 15px;
    height: 3px; }

/* Forms */
.form-message {
  display: none;
  margin: 40px 0;
  padding: 40px 0; }
  .form-message i {
    margin-bottom: 20px;
    font-size: 50px; }

.form-message-success {
  border: 1px solid #3bae3b; }
  .form-message-success i {
    color: #3bae3b; }

.form-message-failed {
  border: 1px solid #f53737; }
  .form-message-failed i {
    color: #f53737; }

.rate {
  display: inline;
  position: relative; }
  .rate::after {
    letter-spacing: 4px;
    font-size: 18px;
    font-family: "FontAwesome";
    font-weight: lighter;
    color: #ff9540; }

.rate-0::after {
  content: "\f006 \f006 \f006 \f006 \f006"; }

.rate-05::after {
  content: "\f123 \f006 \f006 \f006 \f006"; }

.rate-1::after {
  content: "\f005 \f006 \f006 \f006 \f006"; }

.rate-15::after {
  content: "\f005 \f123 \f006 \f006 \f006"; }

.rate-2::after {
  content: "\f005 \f005 \f006 \f006 \f006"; }

.rate-25::after {
  content: "\f005 \f005 \f123 \f006 \f006"; }

.rate-3::after {
  content: "\f005 \f005 \f005 \f006 \f006"; }

.rate-35::after {
  content: "\f005 \f005 \f005 \f123 \f006"; }

.rate-4::after {
  content: "\f005 \f005 \f005 \f005 \f006"; }

.rate-45::after {
  content: "\f005 \f005 \f005 \f005 \f123"; }

.rate-5::after {
  content: "\f005 \f005 \f005 \f005 \f005"; }

.show-phone .cover i {
  margin-right: 20px; }

.show-phone .content {
  display: none; }

/*RWD*/
@media only screen and (max-width: 980px) {
  .display-big {
    display: none; } }

.display-medium {
  display: none; }
  @media only screen and (min-width: 768px) and (max-width: 980px) {
    .display-medium {
      display: inherit; } }

.display-small {
  display: none; }
  @media only screen and (max-width: 767px) {
    .display-small {
      display: inherit; } }

.hide-big {
  display: none; }
  @media only screen and (max-width: 980px) {
    .hide-big {
      display: inherit; } }

@media only screen and (min-width: 768px) and (max-width: 980px) {
  .hide-medium {
    display: none; } }

@media only screen and (max-width: 767px) {
  .hide-small {
    display: none; } }

header {
  z-index: 9000;
  position: fixed;
  top: 0;
  width: 100%;
  background: #17181a;
  padding: 15px 0 10px;
  transition: .5s; }

#logo img {
  max-width: 100%; }

@media only screen and (max-width: 767px) {
  #logo {
    max-width: 190px;
    min-height: 40px; } }

#menu-toggle {
  display: none;
  position: fixed;
  z-index: 9000;
  top: 15px;
  right: 70px; }
  #menu-toggle span {
    display: block;
    width: 35px;
    height: 3px;
    margin: 7px;
    background: white; }
  #menu-toggle div {
    position: absolute;
    right: -55px;
    top: 11px;
    font-weight: bold;
    color: white; }
  #menu-toggle:hover {
    cursor: pointer; }
  @media only screen and (max-width: 767px) {
    #menu-toggle {
      display: block; } }

.fixed-header {
  transform: translate3d(0, -40px, 0);
  padding-bottom: 0; }
  .fixed-header .right-menu li:nth-of-type(2) {
    padding-top: 5px; }

#short-contact {
  opacity: .75;
  padding-top: 5px;
  width: 100%;
  text-align: right;
  margin-bottom: 15px;
  font-size: 17px; }
  #short-contact a {
    color: #fff;
    text-decoration: none; }
    #short-contact a:first-child {
      margin-right: 20px; }
  @media only screen and (max-width: 767px) {
    #short-contact {
      display: none; } }

.header-nav {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 767px) {
    .header-nav {
      flex-direction: column; } }

.main-menu {
  margin-left: 40px; }
  .main-menu ul li {
    padding: 20px 15px;
    float: left; }
    .main-menu ul li a {
      font-size: 18px;
      color: white; }
      .main-menu ul li a:hover {
        text-decoration: none;
        opacity: .8; }
  @media only screen and (max-width: 980px) {
    .main-menu {
      margin-left: 0; } }
  @media only screen and (max-width: 767px) {
    .main-menu {
      display: none;
      margin-left: 0;
      margin-top: 20px; }
      .main-menu ul li {
        padding: 15px 0;
        text-align: center;
        float: inherit; } }

.right-menu li {
  color: white;
  transition: .5s;
  float: left;
  padding: 20px 0; }
  .right-menu li a {
    font-size: 18px;
    color: white; }
    .right-menu li a:hover {
      text-decoration: none;
      opacity: .8; }
  .right-menu li:first-child {
    margin-top: 8px;
    padding: 12px 20px;
    margin-right: 30px; }
  .right-menu li:last-child {
    padding-top: 8px;
    font-weight: bold; }

@media only screen and (max-width: 767px) {
  .right-menu {
    display: none;
    float: inherit;
    text-align: center; }
    .right-menu li {
      float: inherit; }
      .right-menu li:first-child {
        margin-top: 0;
        padding: 12px 0;
        margin-right: 0; } }

#dodaj-kancelarie {
  background: linear-gradient(to right, #3bae3b, #58ba58);
  padding: 13px 35px; }
  #dodaj-kancelarie i {
    transition: .3s; }
  #dodaj-kancelarie:hover {
    text-decoration: none; }
    #dodaj-kancelarie:hover i {
      transform: scale3d(0.9, 0.9, 1); }
  @media only screen and (max-width: 767px) {
    #dodaj-kancelarie {
      display: none; } }

#dodaj-kancelarie-mobile {
  display: none;
  margin-top: 10px;
  color: #fff;
  font-weight: bold;
  background: linear-gradient(to right, #3bae3b, #58ba58);
  padding: 13px 35px; }
  #dodaj-kancelarie-mobile i {
    transition: .3s; }
  #dodaj-kancelarie-mobile:hover {
    text-decoration: none; }
    #dodaj-kancelarie-mobile:hover i {
      transform: scale3d(0.9, 0.9, 1); }
  @media only screen and (max-width: 767px) {
    #dodaj-kancelarie-mobile {
      display: block; } }

/* Breadcrumbs */
.bread-crumbs {
  padding: 20px 0; }
  .bread-crumbs a {
    color: #444; }
    .bread-crumbs a:hover {
      text-decoration: none; }
  .bread-crumbs .current-page {
    font-weight: bold;
    color: #4071ff; }
  @media only screen and (max-width: 767px) {
    .bread-crumbs {
      display: none; } }

.bread-crumbs-full {
  background: #eee; }

footer {
  padding-top: 50px;
  background: #383c46;
  color: #fff; }
  footer h4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 55px; }
    @media only screen and (max-width: 767px) {
      footer h4 {
        margin-bottom: 25px;
        margin-top: 30px; } }

.footer-about p {
  margin-top: 45px;
  font-style: italic;
  font-size: 18px;
  color: #d3d5dc; }

.footer-nav ul li {
  font-size: 18px;
  margin-bottom: 25px; }
  @media only screen and (max-width: 767px) {
    .footer-nav ul li {
      margin-bottom: 15px; } }
  .footer-nav ul li a {
    color: white; }
    .footer-nav ul li a:hover {
      text-decoration: none;
      color: #4071ff; }

.social-box {
  display: inline-block;
  text-align: center;
  color: #6a6d74;
  padding: 10px 0;
  width: 40px;
  font-size: 18px;
  margin-right: 15px;
  position: relative; }
  .social-box::before, .social-box::after {
    content: "";
    position: absolute;
    left: 0;
    transition: .5s; }
  .social-box::before {
    background: #1c1e23;
    top: 0;
    height: 40px;
    width: 40px; }
  .social-box::after {
    bottom: -20px;
    height: 40px;
    width: 0; }
  .social-box:hover {
    color: #fff; }
    .social-box:hover::before {
      width: 0; }
    .social-box:hover::after {
      width: 40px; }
    .social-box:hover:nth-of-type(1)::after {
      background: #3b5999; }
    .social-box:hover:nth-of-type(2)::after {
      background: #55acee; }
    .social-box:hover:nth-of-type(3)::after {
      background: #dd4b39; }
  .social-box .icon {
    width: 100%;
    display: block;
    z-index: 1;
    position: absolute; }

.footer-copyright {
  margin-top: 50px;
  border-top: 1px solid #6a6d74;
  padding: 20px 0; }
  .footer-copyright a {
    color: inherit;
    font-weight: bold; }
    .footer-copyright a:hover {
      text-decoration: none; }
  .footer-copyright .links {
    float: right;
    padding-bottom: 10px; }
    @media only screen and (max-width: 767px) {
      .footer-copyright .links {
        float: none;
        padding-top: 10px; } }
    .footer-copyright .links a {
      font-weight: normal; }
      .footer-copyright .links a:first-child {
        margin-right: 30px; }
      .footer-copyright .links a:last-child {
        border-left: 1px solid #6a6d74;
        padding-left: 30px; }
    .footer-copyright .links img {
      margin-left: 30px;
      margin-bottom: -12px; }

#mobile-footer {
  padding: 30px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }
  @media only screen and (min-width: 980px) {
    #mobile-footer {
      display: none; } }
  #mobile-footer div:last-child {
    margin: 20px 0 10px; }
  #mobile-footer .social-box {
    margin-top: -20px; }

.articles-aside {
  margin: 0; }

#localizations-map {
  margin-bottom: 20px;
  height: 250px;
  width: 100%;
  background: lightgrey;
  box-shadow: inset 0 0 3px #ccc; }

#map {
  height: 100%;
  width: 100%; }

#polecani-prawnicy-widget {
  background: #fcf7ef;
  border: 1px solid #e2d9cb; }
  #polecani-prawnicy-widget .widget-heading {
    padding: 20px;
    border-bottom: 1px solid #bdb9b3;
    font-size: 18px; }
  #polecani-prawnicy-widget .ppw-loop__element {
    padding: 20px; }
    #polecani-prawnicy-widget .ppw-loop__element .avatar {
      width: 70px;
      height: 70px;
      border-radius: 35px; }
      #polecani-prawnicy-widget .ppw-loop__element .avatar img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 35px; }
    #polecani-prawnicy-widget .ppw-loop__element .info {
      margin-left: 10px; }
    #polecani-prawnicy-widget .ppw-loop__element .name, #polecani-prawnicy-widget .ppw-loop__element .category, #polecani-prawnicy-widget .ppw-loop__element .location {
      font-weight: bold;
      display: block; }
    #polecani-prawnicy-widget .ppw-loop__element .name {
      font-size: 20px; }
    #polecani-prawnicy-widget .ppw-loop__element .category {
      margin: 5px 0 10px;
      font-size: 15px; }
    #polecani-prawnicy-widget .ppw-loop__element .location {
      margin: 10px 0 20px;
      font-size: 16px;
      font-weight: bold; }
    #polecani-prawnicy-widget .ppw-loop__element .button {
      color: #4071ff;
      font-size: 13.5px;
      padding: 10px 10px;
      background: #e2ded7;
      text-align: center;
      font-weight: bold; }
      #polecani-prawnicy-widget .ppw-loop__element .button:hover {
        background: #4071ff;
        color: white; }
  #polecani-prawnicy-widget .ppw-loop .slick-dots {
    position: absolute;
    top: -45px;
    right: 10px;
    display: flex;
    justify-content: flex-end; }
    #polecani-prawnicy-widget .ppw-loop .slick-dots li {
      margin: 0 3px; }
      #polecani-prawnicy-widget .ppw-loop .slick-dots li button {
        position: relative;
        width: 16px;
        height: 16px;
        background: 0 0;
        border: none;
        border-radius: 8px;
        padding: 0;
        font-size: 0; }
        #polecani-prawnicy-widget .ppw-loop .slick-dots li button::after {
          position: absolute;
          top: -15px;
          left: 4px;
          content: ".";
          font-size: 25px; }
        #polecani-prawnicy-widget .ppw-loop .slick-dots li button:hover {
          cursor: pointer; }
    #polecani-prawnicy-widget .ppw-loop .slick-dots .slick-active button {
      border: 1px solid #000; }
      #polecani-prawnicy-widget .ppw-loop .slick-dots .slick-active button::after {
        display: none; }

#inne-uslugi-widget .green-underscore {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 25px; }

#inne-uslugi-widget ul li {
  margin: 15px 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  font-size: 18px; }
  #inne-uslugi-widget ul li a {
    color: inherit; }
    #inne-uslugi-widget ul li a:hover {
      color: #4071ff;
      text-decoration: none; }
  #inne-uslugi-widget ul li:last-child {
    border: none; }

/*articles*/
.articles-aside-col {
  padding: 0; }
  .articles-aside-col #polecani-prawnicy-widget {
    margin: 0; }
    .articles-aside-col #polecani-prawnicy-widget .picture {
      width: 70px;
      height: 70px; }
    .articles-aside-col #polecani-prawnicy-widget .top .pagination ul li {
      margin: 0 3px; }
    .articles-aside-col #polecani-prawnicy-widget .polecany-prawnik-contact a {
      font-size: 13.5px;
      padding: 10px 10px; }
  @media only screen and (max-width: 767px) {
    .articles-aside-col {
      display: none; } }

#polecani-prawnicy-minimal-widget {
  padding: 20px 30px 30px;
  position: relative; }
  #polecani-prawnicy-minimal-widget::before {
    position: absolute;
    content: "";
    background: #fbd58d;
    width: 110%;
    height: 100%;
    left: 0;
    top: 0;
    margin-left: -15px;
    z-index: 0; }
  #polecani-prawnicy-minimal-widget h3 {
    margin: 10px 0 35px;
    font-size: 18px;
    position: relative; }
    #polecani-prawnicy-minimal-widget h3::before, #polecani-prawnicy-minimal-widget h3::after {
      position: absolute;
      content: ""; }
    #polecani-prawnicy-minimal-widget h3::before {
      height: 1px;
      width: calc(100% + 35px);
      left: -45px;
      bottom: -15px;
      background: #fff; }
    #polecani-prawnicy-minimal-widget h3::after {
      height: 3px;
      width: 12px;
      left: 0;
      bottom: -15px;
      background: #3bae3b; }
  #polecani-prawnicy-minimal-widget .items {
    display: flex;
    flex-flow: row wrap; }
    #polecani-prawnicy-minimal-widget .items .profile {
      display: block;
      margin: 5px 0;
      width: 50%;
      position: relative; }
      #polecani-prawnicy-minimal-widget .items .profile::before {
        content: "";
        position: absolute;
        background: #4071ff;
        opacity: 0;
        height: 100px;
        top: 0;
        transition: .5s; }
      #polecani-prawnicy-minimal-widget .items .profile:nth-child(2n+1) {
        padding-right: 5px; }
        #polecani-prawnicy-minimal-widget .items .profile:nth-child(2n+1)::before {
          width: calc(100% - 10px);
          left: 0; }
      #polecani-prawnicy-minimal-widget .items .profile:nth-child(2n) {
        padding-left: 5px; }
        #polecani-prawnicy-minimal-widget .items .profile:nth-child(2n)::before {
          width: calc(100% - 13px);
          left: 5px; }
      #polecani-prawnicy-minimal-widget .items .profile:hover::before {
        opacity: .4; }
      #polecani-prawnicy-minimal-widget .items .profile:hover .name {
        opacity: 1; }
      #polecani-prawnicy-minimal-widget .items .profile img {
        display: block;
        max-width: 100%;
        height: 100px;
        object-fit: cover; }
      #polecani-prawnicy-minimal-widget .items .profile .name {
        width: 96%;
        position: absolute;
        top: 40%;
        left: 0;
        color: #fff;
        font-weight: bold;
        font-size: 13px;
        text-align: center;
        opacity: 0;
        transition: .5s; }
        #polecani-prawnicy-minimal-widget .items .profile .name svg {
          margin-bottom: 10px; }
    #polecani-prawnicy-minimal-widget .items .you {
      position: relative; }
      #polecani-prawnicy-minimal-widget .items .you:hover::before {
        opacity: .4;
        background: white; }
      #polecani-prawnicy-minimal-widget .items .you::after {
        position: absolute;
        content: "";
        left: 5px;
        top: 0;
        width: calc(100% - 12px);
        height: 100px;
        border: 1px solid #fff; }
      #polecani-prawnicy-minimal-widget .items .you img {
        opacity: 0; }
      #polecani-prawnicy-minimal-widget .items .you .name {
        opacity: 1;
        color: #000; }
        #polecani-prawnicy-minimal-widget .items .you .name svg {
          font-size: 20px;
          color: #fff; }

#popularne-artykuly-widget {
  padding: 20px 15px;
  padding-right: 0;
  background: #f4f4f4; }
  #popularne-artykuly-widget .widget-heading {
    margin: 10px 0 35px;
    font-size: 18px;
    position: relative; }
    #popularne-artykuly-widget .widget-heading::before, #popularne-artykuly-widget .widget-heading::after {
      position: absolute;
      content: ""; }
    #popularne-artykuly-widget .widget-heading::before {
      height: 1px;
      width: calc(100% + 35px);
      left: -45px;
      bottom: -15px;
      background: #fff; }
    #popularne-artykuly-widget .widget-heading::after {
      height: 3px;
      width: 12px;
      left: 0;
      bottom: -15px;
      background: #3bae3b; }
  #popularne-artykuly-widget .article {
    display: flex;
    padding: 15px 0;
    border-bottom: 1px dotted #cccccc; }
    #popularne-artykuly-widget .article:first-child {
      padding-top: 0; }
    #popularne-artykuly-widget .article:last-child {
      border-bottom: none; }
    #popularne-artykuly-widget .article .image {
      position: relative;
      display: block; }
      #popularne-artykuly-widget .article .image img {
        width: 70px;
        height: 70px;
        margin-right: 15px;
        object-fit: cover; }
      #popularne-artykuly-widget .article .image::after {
        position: absolute;
        content: "";
        width: 70px;
        height: 70px;
        left: 0;
        top: 0;
        background: #4071ff;
        opacity: 0;
        transition: .5s;
        z-index: 999; }
    #popularne-artykuly-widget .article .category {
      font-size: 15px;
      color: #888888; }
    #popularne-artykuly-widget .article .title {
      display: block;
      color: #000;
      margin: 10px 0;
      font-weight: bold;
      line-height: 20px;
      font-size: 18px; }
    #popularne-artykuly-widget .article .link {
      font-weight: bold;
      color: #4071ff;
      font-size: 14px; }
      #popularne-artykuly-widget .article .link i {
        font-size: 10px; }
    #popularne-artykuly-widget .article:hover .image::after {
      opacity: .8; }

.content-box {
  position: relative;
  margin: 30px 0 20px 0;
  border: 1px solid #cccccc;
  overflow: hidden;
  transition: .5s; }
  .content-box::after {
    position: absolute;
    content: "";
    top: -1px;
    left: 20px;
    width: 15px;
    height: 3px;
    background: #3bae3b; }
  .content-box .heading {
    padding: 20px;
    font-size: 18px;
    font-weight: bold; }
  .content-box .elements {
    padding: 0 20px 20px; }

.content-box-full {
  padding-bottom: 0; }
  .content-box-full .elements {
    margin-bottom: 0; }

.content-box-simple {
  border: none;
  border-bottom: 1px solid #cccccc; }
  .content-box-simple::after {
    display: none; }

.content-box-tabs .tabs {
  display: flex;
  background: #f5f5f5;
  border-bottom: 1px solid #cccccc; }
  @media only screen and (max-width: 767px) {
    .content-box-tabs .tabs {
      flex-direction: column; } }
  .content-box-tabs .tabs .tab {
    padding: 20px;
    font-size: 18px; }
    @media only screen and (max-width: 767px) {
      .content-box-tabs .tabs .tab {
        padding: 15px 20px; } }
    .content-box-tabs .tabs .tab:hover {
      cursor: pointer; }
  .content-box-tabs .tabs .active {
    padding: 16px 20px 21px;
    margin-bottom: -1px;
    margin-left: -1px;
    border-top: 3px solid #3bae3b;
    border-right: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
    background: white; }
    @media only screen and (max-width: 767px) {
      .content-box-tabs .tabs .active {
        padding: 15px 20px;
        border-right: none;
        border-left: 4px solid #3bae3b;
        border-bottom: 1px solid #cccccc;
        border-top: 1px solid #cccccc; } }

.content-box-tabs .tab-content {
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;
  display: none; }
  .content-box-tabs .tab-content:nth-child(1) {
    display: inherit; }
  .content-box-tabs .tab-content p {
    font-size: 16px;
    line-height: 1.5;
    text-align: justify; }
  .content-box-tabs .tab-content h1, .content-box-tabs .tab-content h2, .content-box-tabs .tab-content h3, .content-box-tabs .tab-content h4, .content-box-tabs .tab-content h5, .content-box-tabs .tab-content h6 {
    font-size: 22px;
    margin: 0;
    line-height: 1.5; }
  .content-box-tabs .tab-content ul li {
    padding-left: 0px;
    margin: 5px 0;
    font-size: 16px;
    line-height: 1.5; }
    .content-box-tabs .tab-content ul li h1, .content-box-tabs .tab-content ul li h2, .content-box-tabs .tab-content ul li h3, .content-box-tabs .tab-content ul li h4, .content-box-tabs .tab-content ul li h5, .content-box-tabs .tab-content ul li h6, .content-box-tabs .tab-content ul li p {
      font-size: 16px;
      font-weight: 400;
      display: inline-block;
      margin: 0;
      line-height: 1.5; }
    .content-box-tabs .tab-content ul li::before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      padding: 3px;
      background: #4071ff;
      border-radius: 10px; }

.content-box-tabs::after {
  display: none; }

#profile-specjalizacje .element {
  position: relative;
  padding: 12px 0 12px 35px;
  font-size: 18px; }
  #profile-specjalizacje .element::before {
    position: absolute;
    font-family: 'FontAwesome';
    content: "\f05d";
    font-size: 25px;
    color: #3bae3b;
    left: 0;
    top: 8px; }

.form-group {
  margin: 20px 0; }
  .form-group label {
    display: block; }

.custom-checkbox {
  position: relative;
  margin: 5px 0; }
  .custom-checkbox__input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 999; }
    .custom-checkbox__input:checked ~ .custom-checkbox__label::before {
      background: #4071ff; }
    .custom-checkbox__input:hover {
      cursor: pointer; }
  .custom-checkbox__label {
    position: relative;
    font-size: 16px;
    margin-left: 27px;
    z-index: 0; }
    .custom-checkbox__label::before {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      left: -25px;
      top: 1px;
      border: 2px solid #4071ff; }
    .custom-checkbox__label::after {
      content: "✔";
      position: absolute;
      left: -22px;
      top: 3px;
      color: white; }

.form label {
  display: block;
  font-size: 18px;
  margin: 30px 0 10px; }
  .form label:first-of-type {
    margin-top: 0; }

.form .required::after {
  content: "*";
  color: #f53737; }

.form .stars {
  display: flex; }
  .form .stars .inputs .option {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    text-align: center;
    border: 1px solid #cccccc;
    margin-top: 10px;
    margin-right: -5.3px;
    box-shadow: 0 5px 5px #efefef; }
    .form .stars .inputs .option .input-radio {
      visibility: hidden;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 99; }
    .form .stars .inputs .option i {
      margin-top: 15px;
      color: #ff9540; }
  @media only screen and (max-width: 767px) {
    .form .stars .inputs {
      margin: auto;
      margin-top: -20px; } }
  .form .stars .rate {
    margin-top: 25px;
    margin-bottom: 40px;
    margin-left: 25px;
    font-size: 18px; }
    .form .stars .rate span {
      color: #ff9540; }
    @media only screen and (max-width: 767px) {
      .form .stars .rate {
        text-align: center;
        margin-top: 25px;
        margin-bottom: 20px; } }
  @media only screen and (max-width: 767px) {
    .form .stars {
      flex-direction: column; } }

.form .textarea {
  width: 100%;
  box-shadow: inset 0 0 5px #efe;
  border: 1px solid #cccccc;
  min-height: 80px;
  padding: 10px; }
  .form .textarea::placeholder {
    font-style: italic;
    font-size: 18px; }

.form .form-group {
  margin-top: 5px; }

.form .submit-alert {
  display: inline-block;
  margin-right: 20px;
  font-size: 18px; }
  .form .submit-alert a {
    color: inherit; }

.form .button-blue {
  display: inline-block;
  background: #4071ff;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 18px;
  width: 150px;
  box-shadow: 0 5px 5px #efefef;
  transition: .5s; }
  .form .button-blue:hover {
    box-shadow: none; }

.form .checklist {
  margin: 30px 0 10px; }
  .form .checklist__element {
    position: relative;
    margin: 5px 0; }
    .form .checklist__element input {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 25px;
      height: 25px;
      margin: 0;
      z-index: 999; }
      .form .checklist__element input:checked ~ label::after {
        opacity: 1; }
      .form .checklist__element input:hover {
        cursor: pointer; }
    .form .checklist__element label {
      color: #444;
      position: relative;
      font-size: 16px;
      line-height: 24px;
      margin-left: 35px;
      z-index: 0; }
      .form .checklist__element label a {
        font-weight: bold;
        color: #4071ff; }
      .form .checklist__element label::before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        left: -35px;
        top: 1px;
        border: 1px solid #cccccc;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); }
      .form .checklist__element label::after {
        content: "✔";
        position: absolute;
        left: -35px;
        top: -5px;
        font-size: 28px;
        color: #3bae3b;
        opacity: 0;
        transition: .5s; }

.form .form-error {
  margin-top: 30px;
  padding: 15px;
  color: #f53737;
  border: 1px solid #f53737;
  position: relative; }
  .form .form-error::before {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    height: calc(100% - 4px);
    width: 2px;
    background: #f53737; }

.compact-form {
  display: flex; }
  .compact-form input, .compact-form .button {
    font-size: 18px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
    font-weight: bold; }
  .compact-form input {
    border: none;
    padding-left: 15px; }
  .compact-form .button {
    transition: .5s; }
    .compact-form .button:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05); }
  @media only screen and (max-width: 767px) {
    .compact-form {
      flex-direction: column; }
      .compact-form input {
        margin: 10px 0; } }

.form-group .row {
  margin-left: -5px;
  margin-right: -5px; }
  .form-group .row > div {
    padding-right: 5px;
    padding-left: 5px; }

.form-group select, .form-group input, .form-group textarea {
  border: 1px solid #cccccc;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  padding: 12px 15px;
  width: 100%;
  transition: .5s; }
  .form-group select:focus, .form-group input:focus, .form-group textarea:focus {
    border-color: #4071ff; }

.form-group select {
  appearance: none;
  background-image: url(../img/arrow-down.png);
  background-repeat: no-repeat;
  background-position: 95% center; }

@media only screen and (max-width: 767px) {
  .form-group input {
    margin-bottom: 10px; } }

.form-group label {
  font-size: 16px;
  margin-top: 50px; }

@media only screen and (max-width: 767px) {
  .form-group input, .form-group select {
    margin-bottom: 10px; } }

.init-popup:hover {
  cursor: pointer; }

.popup {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9000; }
  .popup__inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .popup__content {
    position: relative;
    width: 630px;
    max-width: 100%;
    background: #fff; }
  .popup section {
    padding: 0 30px; }

.close-popup:hover {
  cursor: pointer; }

.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 30px;
  transition: .5s; }
  .close-icon::before, .close-icon::after {
    content: "";
    position: absolute;
    background: #7f7f7f;
    width: 18px;
    height: 6px;
    border-radius: 2px; }
  .close-icon::before {
    transform: rotate(45deg);
    transition: .5s; }
  .close-icon::after {
    transform: rotate(-45deg);
    transition-duration: .5s;
    transition-delay: .1s; }
  .close-icon:hover {
    transform: translate3d(-3px, 0, 0); }
    .close-icon:hover::before, .close-icon:hover::after {
      width: 25px; }

@media only screen and (max-width: 767px) {
  #wyslij-wiadomosc-popup h3 {
    font-size: 18px; } }

#wyslij-wiadomosc-popup .picture {
  margin-top: 10px;
  width: 90px; }

@media only screen and (max-width: 767px) {
  #wyslij-wiadomosc-popup .rating {
    display: none; } }

#wyslij-wiadomosc-popup .rate-count {
  color: #ff9540; }

#wyslij-wiadomosc-popup textarea {
  height: 200px; }
  @media only screen and (max-width: 767px) {
    #wyslij-wiadomosc-popup textarea {
      height: 140px; } }

#wyslij-wiadomosc-popup .button {
  width: 100%;
  padding: 15px; }

#wyslij-wiadomosc-popup .popup__content {
  padding-bottom: 20px; }

@media only screen and (max-width: 767px) {
  #sprawdz-cennik-popup h3 {
    font-size: 18px; } }

#sprawdz-cennik-popup .popup__content {
  position: relative; }
  #sprawdz-cennik-popup .popup__content::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(transparent, #eee); }

#sprawdz-cennik-popup .picture {
  margin-top: 10px;
  width: 90px; }

@media only screen and (max-width: 767px) {
  #sprawdz-cennik-popup .rating {
    display: none; } }

#sprawdz-cennik-popup .rate-count {
  color: #ff9540; }

#sprawdz-cennik-popup .buttons {
  margin-bottom: 20px;
  display: inline-block;
  width: 280px; }
  #sprawdz-cennik-popup .buttons .button {
    height: 35px;
    padding-top: 10px; }
  #sprawdz-cennik-popup .buttons .row > div {
    margin: 5px 0;
    padding: 0 5px; }
  @media only screen and (max-width: 767px) {
    #sprawdz-cennik-popup .buttons {
      display: none; } }

#sprawdz-cennik-popup section:last-of-type {
  margin-top: 25px;
  padding-top: 20px;
  background: #eee;
  overflow-y: scroll; }

#sprawdz-cennik-popup .table-title {
  margin: 20px 0;
  padding-bottom: 5px;
  color: #4071ff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  border-bottom: 1px solid #cccccc; }

#sprawdz-cennik-popup table {
  width: 100%;
  font-size: 18px; }
  #sprawdz-cennik-popup table td {
    border-bottom: 1px solid lightgray;
    padding: 10px 0; }
    @media only screen and (max-width: 767px) {
      #sprawdz-cennik-popup table td {
        font-size: 16px; } }
    #sprawdz-cennik-popup table td:nth-child(2) {
      text-align: right;
      font-weight: bold;
      min-width: 80px; }
    #sprawdz-cennik-popup table td i {
      color: #3bae3b;
      margin-left: 10px; }
      @media only screen and (max-width: 767px) {
        #sprawdz-cennik-popup table td i {
          display: none; } }
  #sprawdz-cennik-popup table:last-child {
    margin-bottom: 60px; }

@media only screen and (max-width: 767px) {
  #sprawdz-cennik-popup .back-to-profile {
    display: none; } }

#login-popup .popup__content {
  width: 430px !important; }
  #login-popup .popup__content img {
    width: 75%;
    margin: auto;
    display: block;
    margin-top: 30px; }
  #login-popup .popup__content p {
    font-size: 15px;
    margin: 20px 0; }
    #login-popup .popup__content p a {
      color: #4071ff;
      display: inline; }

#login-popup .login-form {
  margin-top: 10px; }
  #login-popup .login-form input {
    margin-top: 20px;
    width: 100%;
    font-size: 18px;
    padding: 10px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc; }
  #login-popup .login-form #haslo {
    letter-spacing: 1px; }
  #login-popup .login-form .aditionals {
    margin: 20px 0;
    display: flex;
    justify-content: space-between; }
  #login-popup .login-form .custom-checkbox {
    margin: 0; }
    #login-popup .login-form .custom-checkbox__input {
      margin-top: 0; }
  #login-popup .login-form .button {
    margin: 10px 0;
    background: linear-gradient(to right, #4071ff, #4b8fff);
    font-size: 18px;
    color: white; }
    #login-popup .login-form .button:hover {
      box-shadow: inset 0 4px 15px rgba(0, 0, 0, 0.15); }
  #login-popup .login-form .social-login .button {
    border: none;
    position: relative; }
    #login-popup .login-form .social-login .button::after {
      font-family: 'FontAwesome';
      position: absolute;
      top: 20px; }
    #login-popup .login-form .social-login .button::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 70px;
      background: rgba(255, 255, 255, 0.15);
      transition: .5s; }
    #login-popup .login-form .social-login .button:first-child {
      background: #3b5998; }
      #login-popup .login-form .social-login .button:first-child::after {
        left: 30px;
        content: "\f09a"; }
    #login-popup .login-form .social-login .button:last-child {
      background: #dd4b39; }
      #login-popup .login-form .social-login .button:last-child::after {
        left: 27px;
        content: "\f0d5"; }
    #login-popup .login-form .social-login .button:hover::before {
      width: 0; }

#no-data-popup img {
  max-width: 250px;
  margin: 40px 0 20px; }

#no-data-popup .button-blue {
  margin: 20px 0; }

#no-data-popup .close-popup {
  margin-bottom: 20px; }

.pagination {
  margin: 20px 0 50px;
  display: flex;
  justify-content: flex-end; }
  @media only screen and (max-width: 767px) {
    .pagination {
      justify-content: space-between; } }
  .pagination .pages {
    display: flex;
    margin-top: 15px; }
    .pagination .pages li {
      margin-right: 10px; }
      .pagination .pages li a {
        color: black;
        padding: 11px 14px;
        border: 1px solid #ddd;
        box-shadow: 0 3px 3px #f4f4f4; }
        .pagination .pages li a:hover {
          text-decoration: none;
          box-shadow: none; }
      @media only screen and (max-width: 767px) {
        .pagination .pages li:nth-child(4) {
          display: none; } }
      @media screen and (max-width: 380px) {
        .pagination .pages li:nth-child(3) {
          display: none; } }
    .pagination .pages .current a {
      color: white;
      background: #4071ff;
      border-color: #4071ff; }
  .pagination .button {
    margin-left: 60px;
    padding: 14px 20px;
    float: right;
    width: initial;
    color: black;
    border: 1px solid #ddd;
    box-shadow: 0 3px 3px #f4f4f4; }
    .pagination .button:hover {
      box-shadow: none; }
    @media only screen and (max-width: 767px) {
      .pagination .button {
        margin-left: 20px; } }

#polecani-prawnicy-widget .pagination {
  margin: 0; }

.badge {
  margin: 15px 0;
  padding: 20px;
  border: 1px solid #cccccc; }

.badge-red {
  border-color: #f53737;
  color: #f53737; }

.badge-green {
  border-color: #3bae3b;
  color: #3bae3b; }

#home-baner {
  background-image: url(../img/homepage-header-bg.jpg);
  background-position: center center;
  background-size: cover;
  padding: 200px 0 60px;
  color: white; }
  @media only screen and (max-width: 767px) {
    #home-baner {
      padding: 120px 0 40px; } }
  #home-baner h1 {
    font-size: 60px;
    line-height: 90px;
    margin-bottom: 30px;
    font-weight: bold; }
    @media only screen and (max-width: 767px) {
      #home-baner h1 {
        font-size: 40px;
        line-height: 58px; } }
  #home-baner h5 {
    font-size: 24px;
    font-weight: lighter; }
    @media only screen and (max-width: 767px) {
      #home-baner h5 {
        font-size: 20px;
        line-height: 26px; } }
  #home-baner .scroll-down-below {
    font-size: 16px;
    transition: .5s; }
    #home-baner .scroll-down-below:hover {
      opacity: .7;
      cursor: pointer; }
    @media only screen and (max-width: 767px) {
      #home-baner .scroll-down-below {
        display: none; } }

#home-form-search {
  background: black;
  margin: 40px 0 70px;
  padding: 10px 15px; }
  #home-form-search input {
    border: none;
    width: 100%;
    height: 100%;
    padding: 0 15px;
    font-size: 18px;
    font-weight: bold; }
    #home-form-search input:hover {
      cursor: pointer; }
    @media only screen and (max-width: 767px) {
      #home-form-search input {
        height: 60px;
        margin-bottom: 10px; } }
  #home-form-search .submit {
    font-size: 18px; }
    #home-form-search .submit i {
      margin-right: 10px; }
    #home-form-search .submit:hover {
      opacity: .85; }
  #home-form-search label {
    position: absolute;
    right: 25px;
    top: 20px;
    color: gray; }
    #home-form-search label:hover {
      cursor: pointer; }
  #home-form-search .row > div {
    padding: 0 10px; }
    @media only screen and (min-width: 768px) {
      #home-form-search .row > div:nth-child(2) {
        padding: 0; }
        #home-form-search .row > div:nth-child(2) label {
          right: 15px; } }
  #home-form-search .input-select {
    display: none;
    position: absolute;
    z-index: 500;
    margin-top: 1px;
    background: white;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    width: calc(100% - 20px); }
    #home-form-search .input-select li {
      padding: 10px 15px;
      color: black;
      position: relative; }
      #home-form-search .input-select li::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 3px;
        height: 100%;
        background: #4071ff;
        transition: .5s;
        opacity: 0; }
      #home-form-search .input-select li:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.02); }
        #home-form-search .input-select li:hover::before {
          opacity: 1; }
  #home-form-search .input-select-localization {
    width: 100%; }

#kategorie {
  display: flex;
  border-bottom: 1px solid #dddddd; }
  #kategorie li {
    float: left;
    padding: 35px 0;
    margin: 0 15px;
    position: relative; }
    #kategorie li:first-child {
      margin-left: 0; }
    #kategorie li::after {
      content: "";
      position: absolute;
      height: 2px;
      background: #4071ff;
      width: 100%;
      left: 0;
      bottom: -1px;
      opacity: 0;
      transition: .5s; }
    #kategorie li a {
      font-size: 18px;
      color: inherit;
      transition: .5s; }
    #kategorie li:hover::after {
      opacity: 1; }
    #kategorie li:hover a {
      color: #4071ff;
      text-decoration: none; }
    @media only screen and (max-width: 767px) {
      #kategorie li {
        display: none; } }

#jak-to-dziala {
  padding: 80px 0; }
  @media only screen and (max-width: 767px) {
    #jak-to-dziala {
      padding: 40px 0 0; } }
  #jak-to-dziala .steps {
    padding-top: 20px;
    display: flex; }
    @media only screen and (max-width: 767px) {
      #jak-to-dziala .steps {
        flex-wrap: wrap; } }
    #jak-to-dziala .steps .step {
      position: relative;
      flex: 1;
      text-align: center;
      padding: 20px; }
      #jak-to-dziala .steps .step .img {
        height: 90px; }
      #jak-to-dziala .steps .step p {
        font-size: 15px; }
      #jak-to-dziala .steps .step::after {
        content: "...............";
        font-size: 20px;
        letter-spacing: 2px;
        position: absolute;
        top: 45px;
        right: -50px; }
      #jak-to-dziala .steps .step:nth-child(4)::after, #jak-to-dziala .steps .step:nth-child(5)::after {
        display: none; }
      #jak-to-dziala .steps .step:last-child {
        background: #5884ff;
        color: white; }
        @media only screen and (max-width: 767px) {
          #jak-to-dziala .steps .step:last-child {
            margin: 0 -15px;
            display: flex;
            text-align: left; }
            #jak-to-dziala .steps .step:last-child .img {
              text-align: center;
              align-self: center;
              flex: 80%; }
            #jak-to-dziala .steps .step:last-child a {
              display: block;
              margin-top: 10px;
              font-weight: bold; } }
      @media only screen and (max-width: 767px) {
        #jak-to-dziala .steps .step {
          flex: 50%;
          padding: 15px; }
          #jak-to-dziala .steps .step::after {
            right: inherit;
            left: -10px;
            top: 0;
            font-size: 110px;
            color: #f1f1f1;
            font-weight: bold; }
          #jak-to-dziala .steps .step:nth-child(1)::after {
            content: "1"; }
          #jak-to-dziala .steps .step:nth-child(2)::after {
            content: "2"; }
          #jak-to-dziala .steps .step:nth-child(3)::after {
            content: "3"; }
          #jak-to-dziala .steps .step:nth-child(4)::after {
            display: block;
            content: "4"; } }

#polecane-kancelarie {
  padding-bottom: 40px; }
  @media only screen and (max-width: 767px) {
    #polecane-kancelarie {
      display: none; } }
  #polecane-kancelarie .heading {
    margin: 50px 0; }
  #polecane-kancelarie #polecane-kancelarie-carousel {
    width: 100%; }
  #polecane-kancelarie .button-outline, #polecane-kancelarie #dolacz-do-spolecznosci input, #dolacz-do-spolecznosci #polecane-kancelarie input, #polecane-kancelarie #dolacz-do-spolecznosci .button, #dolacz-do-spolecznosci #polecane-kancelarie .button {
    color: #000;
    margin-top: 40px; }
    #polecane-kancelarie .button-outline:hover, #polecane-kancelarie #dolacz-do-spolecznosci input:hover, #dolacz-do-spolecznosci #polecane-kancelarie input:hover, #polecane-kancelarie #dolacz-do-spolecznosci .button:hover, #dolacz-do-spolecznosci #polecane-kancelarie .button:hover {
      border-color: #ddd; }
  #polecane-kancelarie .container {
    position: relative; }
    #polecane-kancelarie .container .next, #polecane-kancelarie .container .prev {
      position: absolute;
      top: calc(50% - 21px); }
      #polecane-kancelarie .container .next:hover, #polecane-kancelarie .container .prev:hover {
        cursor: pointer; }
      @media only screen and (max-width: 767px) {
        #polecane-kancelarie .container .next, #polecane-kancelarie .container .prev {
          display: none; } }
    #polecane-kancelarie .container .next {
      right: -40px; }
    #polecane-kancelarie .container .prev {
      display: none;
      left: -40px; }

.offer-box {
  height: 100%;
  position: relative;
  border: 1px solid #ddd;
  border-top: 2px solid #efba67;
  padding: 0 15px;
  text-align: center;
  font-weight: bold;
  transition: transform .5s; }
  .offer-box .icon {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 8px 15px 10px;
    font-size: 20px;
    background: #efba67;
    color: white;
    font-family: Arial; }
  .offer-box .picture {
    display: inline-block;
    height: 90px;
    width: 90px;
    margin: 30px auto 20px;
    background-position: center;
    background-size: cover; }
  .offer-box .rating {
    margin-bottom: 20px;
    color: #ff9540; }
    .offer-box .rating span {
      color: #888; }
  .offer-box .name {
    display: block;
    line-height: 24px;
    font-size: 20px; }
  .offer-box .localization {
    display: block;
    margin: 20px 0;
    color: #888; }
  .offer-box:hover {
    border: 2px solid #4071ff;
    transform: translate3d(0, -10px, 0);
    box-shadow: 0 10px 20px #d0dcff; }
    .offer-box:hover .icon {
      background: #4071ff; }
    .offer-box:hover .name {
      color: #4071ff; }

.sets {
  min-height: 310px;
  display: flex; }
  .sets .set {
    transition: .5s;
    display: none;
    opacity: 0; }
    .sets .set:first-of-type {
      display: inherit;
      opacity: 1; }

/*
#najlepsze-miejsce{
    background: #eee;
    .heading{
        margin: 50px 0 40px;
        line-height: 48px;
    }
    .title-icon{
        width: 100%;
        text-align: center;
        color: $blue;
        font-size: 20px;
        background: #fff;
        padding: 25px 0;
        position: relative;
        &::before,&::after{
            content: "";
            position: absolute;
            width: 10px; height: 10px;
        }
        &::before{
            left: 0; bottom: 0;
            border-left: 1px solid black;
            border-bottom: 1px solid black;
        }
        &::after{
            right: 0; top: 0;
            border-right: 1px solid black;
            border-top: 1px solid black;
        }
    }
    .title-text{
        margin-top: 27px;
        font-weight: bold;
        font-size: 18px;
    }
    p{
        margin: 30px 0 40px;
        font-size: 18px;
        color: #424242;
    }
}
*/
#zadaj-bezplatne-pytanie {
  padding: 100px 0;
  background: #eee; }
  #zadaj-bezplatne-pytanie .heading {
    font-size: 36px;
    margin: 20px 0 50px;
    line-height: 48px; }
  #zadaj-bezplatne-pytanie p {
    margin-bottom: 50px; }
  #zadaj-bezplatne-pytanie .button {
    display: inline-block;
    width: auto;
    border: none; }
    #zadaj-bezplatne-pytanie .button:first-of-type {
      background: #4071ff;
      color: white; }
      #zadaj-bezplatne-pytanie .button:first-of-type:hover {
        background: #6d92ff;
        box-shadow: 0 10px 15px rgba(109, 146, 255, 0.5);
        transform: translate3d(0, -5px, 0); }
    #zadaj-bezplatne-pytanie .button:last-of-type {
      border-color: transparent;
      color: #fff; }
      #zadaj-bezplatne-pytanie .button:last-of-type:hover {
        text-shadow: 0 10px 15px rgba(0, 0, 0, 0.25); }
  #zadaj-bezplatne-pytanie h5 {
    margin-bottom: 15px; }
  @media only screen and (max-width: 767px) {
    #zadaj-bezplatne-pytanie {
      padding: 40px 0; }
      #zadaj-bezplatne-pytanie .heading {
        font-size: 30px;
        margin: 0;
        line-height: 36px; }
      #zadaj-bezplatne-pytanie p {
        margin-top: 20px;
        margin-bottom: 20px; }
      #zadaj-bezplatne-pytanie .display-small {
        margin-top: 30px; } }

#qas {
  transition: .5s;
  display: flex;
  position: relative; }
  #qas .qa .question .inner, #qas .qa .answer .inner {
    padding: 20px 25px; }
  #qas .qa .question .inner {
    width: 85%;
    background: #ddd;
    float: left; }
    #qas .qa .question .inner p {
      margin-top: 0;
      margin-bottom: 20px; }
    #qas .qa .question .inner .question-footer {
      margin-bottom: 30px;
      color: #666666; }
      #qas .qa .question .inner .question-footer svg {
        margin-left: 15px; }
  #qas .qa .answer .inner {
    width: 75%;
    font-weight: bold;
    background: white;
    float: right;
    margin-top: -30px;
    margin-bottom: 0; }
    #qas .qa .answer .inner p {
      margin: 20px 0; }
    #qas .qa .answer .inner .autor-photo {
      position: absolute;
      background-position: center center;
      background-size: cover;
      width: 70px;
      height: 70px;
      margin-top: -25px;
      margin-left: -125px; }
    #qas .qa .answer .inner::before {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      margin-left: -35px;
      margin-top: 10px;
      background: white;
      transform: skewX(45deg); }
    #qas .qa .answer .inner .author {
      font-weight: normal; }
      #qas .qa .answer .inner .author a {
        color: black;
        text-decoration: underline;
        font-weight: bold; }
    #qas .qa .answer .inner a {
      display: inline-block;
      margin-bottom: 5px;
      color: #4071ff; }

#qas-nav {
  position: absolute;
  top: 0;
  right: 0; }
  #qas-nav img:hover {
    cursor: pointer; }
  #qas-nav img:first-of-type {
    margin-right: 20px; }

#qas-pag {
  position: absolute;
  bottom: 45px;
  left: 10px;
  text-align: center; }
  #qas-pag ul li {
    float: left;
    width: 3px;
    height: 3px;
    margin: 6px 10px;
    border: 1px solid #000;
    border-radius: 20px; }
  #qas-pag ul .active {
    width: 14px;
    height: 14px;
    margin: 0 5px; }
  @media only screen and (max-width: 767px) {
    #qas-pag {
      display: none; } }

#qa-loop .qa {
  display: none; }
  #qa-loop .qa:first-of-type {
    display: inherit; }

#wyroznieni-prawnicy {
  padding-top: 50px;
  background-image: linear-gradient(to bottom, white, white 50%, #383c46 50%); }
  #wyroznieni-prawnicy .heading {
    margin-bottom: 40px; }
  #wyroznieni-prawnicy .lista {
    position: relative; }
    #wyroznieni-prawnicy .lista .offer:nth-of-type(n+9) {
      display: none; }
    @media only screen and (max-width: 767px) {
      #wyroznieni-prawnicy .lista .offer:nth-of-type(n+7) {
        display: none; } }
    #wyroznieni-prawnicy .lista .prev, #wyroznieni-prawnicy .lista .next {
      position: absolute;
      top: calc(50% - 21px); }
      #wyroznieni-prawnicy .lista .prev:hover, #wyroznieni-prawnicy .lista .next:hover {
        cursor: pointer; }
      @media only screen and (max-width: 767px) {
        #wyroznieni-prawnicy .lista .prev, #wyroznieni-prawnicy .lista .next {
          display: none; } }
    #wyroznieni-prawnicy .lista .next {
      right: -44px; }
    #wyroznieni-prawnicy .lista .prev {
      display: none;
      left: -44px; }
  #wyroznieni-prawnicy .offer {
    margin-bottom: 30px; }
    #wyroznieni-prawnicy .offer:nth-child(n+4) .offer-box:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); }
  #wyroznieni-prawnicy .offer-box {
    min-height: 285px;
    background: white;
    border-top: 1px solid #ddd; }
    #wyroznieni-prawnicy .offer-box .category {
      color: #888888;
      position: absolute;
      left: 20px;
      top: 20px; }
    #wyroznieni-prawnicy .offer-box:hover {
      border-top: 2px solid #4071ff; }
  #wyroznieni-prawnicy .button-outline, #wyroznieni-prawnicy #dolacz-do-spolecznosci input, #dolacz-do-spolecznosci #wyroznieni-prawnicy input, #wyroznieni-prawnicy #dolacz-do-spolecznosci .button, #dolacz-do-spolecznosci #wyroznieni-prawnicy .button {
    border-color: #888a90;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 50px; }
    #wyroznieni-prawnicy .button-outline:hover, #wyroznieni-prawnicy #dolacz-do-spolecznosci input:hover, #dolacz-do-spolecznosci #wyroznieni-prawnicy input:hover, #wyroznieni-prawnicy #dolacz-do-spolecznosci .button:hover, #dolacz-do-spolecznosci #wyroznieni-prawnicy .button:hover {
      border-color: white; }
  @media only screen and (max-width: 767px) {
    #wyroznieni-prawnicy {
      background-image: none; }
      #wyroznieni-prawnicy .button-outline, #wyroznieni-prawnicy #dolacz-do-spolecznosci input, #dolacz-do-spolecznosci #wyroznieni-prawnicy input, #wyroznieni-prawnicy #dolacz-do-spolecznosci .button, #dolacz-do-spolecznosci #wyroznieni-prawnicy .button {
        background: #4071ff; } }

#wyroznieni-prawnicy-filter {
  float: right;
  margin-top: 5px; }
  #wyroznieni-prawnicy-filter li {
    float: left;
    margin-left: 30px;
    color: #888888;
    font-size: 16px;
    font-weight: bold;
    transition: .5s; }
    #wyroznieni-prawnicy-filter li:hover {
      color: #4071ff;
      cursor: pointer; }
  #wyroznieni-prawnicy-filter .active {
    color: #000; }
  @media only screen and (max-width: 767px) {
    #wyroznieni-prawnicy-filter {
      display: none; } }

#rekomendacje {
  padding-top: 50px;
  border-top: 2px solid #fff;
  color: #fff; }
  #rekomendacje #see-more-rekomendacje {
    margin-top: 5px;
    float: right;
    color: #c0c1c3;
    font-size: 16px;
    font-weight: bold;
    transition: .5s; }
    #rekomendacje #see-more-rekomendacje:hover {
      text-decoration: none;
      color: #fff; }
  @media only screen and (max-width: 767px) {
    #rekomendacje {
      display: none; } }

#testimonial-loop {
  position: relative; }
  #testimonial-loop .next {
    position: absolute;
    right: 0;
    top: calc(50% - 65px); }
    #testimonial-loop .next:hover {
      cursor: pointer; }
  #testimonial-loop .slick-dots {
    padding: 40px 0;
    width: 100%;
    display: flex;
    justify-content: center; }
    #testimonial-loop .slick-dots li {
      background: none;
      width: 3px;
      height: 3px;
      margin: 6px 13px;
      border: 1px solid #fff;
      border-radius: 20px; }
      #testimonial-loop .slick-dots li:hover {
        cursor: pointer; }
      #testimonial-loop .slick-dots li button {
        border: none;
        background: none;
        font-size: 0; }
    #testimonial-loop .slick-dots .slick-active {
      width: 14px;
      height: 14px;
      margin: 0 8px; }

.rekomendacja {
  position: relative; }
  .rekomendacja .picture {
    width: 90px;
    height: 90px;
    background-position: center;
    background-size: cover; }
  .rekomendacja .content {
    transition: .5s;
    background: #6a6d74;
    padding: 30px;
    position: relative; }
    .rekomendacja .content::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      left: -10px;
      top: 50%;
      transform: skew(45deg);
      background: #6a6d74; }
    .rekomendacja .content p {
      font-size: 20xp;
      color: #fff;
      margin-top: 0;
      margin-bottom: 30px; }
    .rekomendacja .content a {
      font-weight: bold;
      color: #bebfc2; }

#double-cta {
  display: flex; }
  @media only screen and (max-width: 767px) {
    #double-cta {
      flex-wrap: wrap; } }
  #double-cta .cta {
    flex: 1;
    text-align: center;
    color: white;
    padding: 50px 0 50px; }
    #double-cta .cta:first-child {
      background-image: url(../img/home-prawnik-bg.jpg); }
    #double-cta .cta:last-child {
      background-image: url(../img/home-klient-bg.jpg); }
    #double-cta .cta .title {
      font-size: 30px;
      font-weight: bold;
      margin: 30px 0; }
    #double-cta .cta p {
      max-width: 430px;
      margin: auto; }
    #double-cta .cta .button-outline, #double-cta .cta #dolacz-do-spolecznosci input, #dolacz-do-spolecznosci #double-cta .cta input, #double-cta .cta #dolacz-do-spolecznosci .button, #dolacz-do-spolecznosci #double-cta .cta .button {
      max-width: 320px;
      margin: 30px auto 0;
      border-color: white; }
      #double-cta .cta .button-outline:hover, #double-cta .cta #dolacz-do-spolecznosci input:hover, #dolacz-do-spolecznosci #double-cta .cta input:hover, #double-cta .cta #dolacz-do-spolecznosci .button:hover, #dolacz-do-spolecznosci #double-cta .cta .button:hover {
        background: white;
        color: #4071ff; }
    #double-cta .cta:first-child .button-outline:hover, #double-cta .cta:first-child #dolacz-do-spolecznosci input:hover, #dolacz-do-spolecznosci #double-cta .cta:first-child input:hover, #double-cta .cta:first-child #dolacz-do-spolecznosci .button:hover, #dolacz-do-spolecznosci #double-cta .cta:first-child .button:hover {
      color: #3bae3b; }
    @media only screen and (max-width: 767px) {
      #double-cta .cta {
        flex: 100%;
        padding: 40px 20px; } }

#w-twojej-okolicy {
  padding-bottom: 80px;
  border-bottom: 1px solid #ddd;
  font-size: 18px; }
  #w-twojej-okolicy .heading {
    margin: 50px 0 40px; }
    #w-twojej-okolicy .heading small {
      font-size: 18px;
      font-style: italic;
      font-weight: normal; }
  #w-twojej-okolicy h5 {
    margin-bottom: 32px;
    color: #888888;
    font-weight: bold; }
    #w-twojej-okolicy h5::first-letter {
      color: #3bae3b; }
    #w-twojej-okolicy h5:after {
      position: absolute;
      content: "";
      top: 30px;
      left: 15px;
      background: #3bae3b;
      height: 2px;
      width: 12px; }
  #w-twojej-okolicy li {
    margin: 15px 0; }
    #w-twojej-okolicy li:hover {
      font-weight: bold;
      color: #4071ff; }
  #w-twojej-okolicy a {
    color: inherit;
    text-decoration: none;
    transition: .5s; }
    #w-twojej-okolicy a:hover {
      color: #4071ff; }
  #w-twojej-okolicy .inne ul {
    margin-top: -14px; }
  #w-twojej-okolicy .button {
    margin-top: 60px; }
    #w-twojej-okolicy .button:hover {
      opacity: .7; }
  @media only screen and (max-width: 767px) {
    #w-twojej-okolicy {
      padding-bottom: 40px;
      border-bottom: none; }
      #w-twojej-okolicy .button {
        margin-top: 20px; } }

#ostatnie-artykuly {
  padding: 50px 0;
  background: #eeeeee; }
  #ostatnie-artykuly .heading {
    margin-bottom: 50px; }
  #ostatnie-artykuly #see-more-artykuly {
    margin-top: 5px;
    float: right;
    color: #888888;
    font-size: 16px;
    font-weight: bold;
    transition: .5s; }
    #ostatnie-artykuly #see-more-artykuly:hover {
      text-decoration: none;
      color: #4071ff; }
  #ostatnie-artykuly .article .image {
    display: block;
    height: 230px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
    #ostatnie-artykuly .article .image::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #4071ff;
      opacity: 0;
      transition: .5s; }
  #ostatnie-artykuly .article .info {
    height: 188px;
    overflow: hidden;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    background: white;
    padding: 20px;
    position: relative; }
    #ostatnie-artykuly .article .info p {
      margin-top: 8px;
      font-size: 16px;
      line-height: 24px; }
  #ostatnie-artykuly .article .title {
    display: block;
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    color: inherit; }
    #ostatnie-artykuly .article .title:hover {
      text-decoration: none; }
  #ostatnie-artykuly .article .category {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: #888888;
    margin: 15px 0; }
    #ostatnie-artykuly .article .category:hover {
      text-decoration: none; }
  #ostatnie-artykuly .article .more {
    color: #4071ff;
    background: white;
    padding: 30px 20px 20px;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    font-size: 18px;
    display: block;
    font-weight: bold;
    position: relative; }
    #ostatnie-artykuly .article .more svg {
      margin-left: 10px; }
    #ostatnie-artykuly .article .more:hover {
      text-decoration: none; }
    #ostatnie-artykuly .article .more::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background: #4071ff;
      height: 2px;
      width: 0;
      transition: .5s; }
  #ostatnie-artykuly .article:hover .image::after {
    opacity: .7; }
  #ostatnie-artykuly .article:hover .more::after {
    width: 100%; }
  @media only screen and (max-width: 767px) {
    #ostatnie-artykuly {
      padding: 30px 0 0;
      background: #fff; }
      #ostatnie-artykuly .article {
        margin-bottom: 40px; }
        #ostatnie-artykuly .article .more {
          border-bottom: 1px solid #ececec; }
      #ostatnie-artykuly .col-sm-4:last-child .article {
        display: none; }
      #ostatnie-artykuly #see-more-artykuly {
        display: none; } }

#dolacz-do-spolecznosci {
  padding: 130px 0 105px;
  background-image: url(../img/dolacz-do-spolecznosci.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  text-align: center; }
  #dolacz-do-spolecznosci .heading {
    font-size: 42px;
    line-height: 55px; }
  #dolacz-do-spolecznosci p {
    font-size: 20px;
    margin: 50px 0; }
  #dolacz-do-spolecznosci input, #dolacz-do-spolecznosci .button {
    display: inline-block;
    border: none;
    font-weight: bold;
    background: white;
    width: auto;
    color: #000;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.25); }
    #dolacz-do-spolecznosci input:hover, #dolacz-do-spolecznosci .button:hover {
      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05); }
  #dolacz-do-spolecznosci .newsletter-form input, #dolacz-do-spolecznosci .newsletter-form .button {
    font-size: 16px; }
  #dolacz-do-spolecznosci .newsletter-form input[type="email"]:hover {
    cursor: auto; }
  #dolacz-do-spolecznosci .newsletter-form .checklist__element label::after,
  #dolacz-do-spolecznosci .newsletter-form .checklist__element label {
    color: white; }
  @media only screen and (max-width: 767px) {
    #dolacz-do-spolecznosci {
      padding: 80px 0; }
      #dolacz-do-spolecznosci .heading {
        font-size: 30px;
        line-height: 38px; }
      #dolacz-do-spolecznosci p {
        font-size: 20px;
        margin: 30px 0; } }

@media only screen and (max-width: 767px) {
  .search-page #dolacz-do-spolecznosci {
    display: none; }
  .search-page .pagination {
    margin-top: 40px; } }

#search-form {
  padding: 120px 0 120px;
  margin-bottom: 30px;
  background-image: url(../img/search-page-header-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed; }
  #search-form #home-form-search {
    background: rgba(255, 255, 255, 0.3); }
  @media only screen and (max-width: 767px) {
    #search-form {
      padding: 20px 0 0; } }

/* main */
#results-for {
  font-weight: bold;
  font-size: 24px;
  margin: 20px 0; }
  @media only screen and (max-width: 767px) {
    #results-for {
      display: none; } }

#sort-by {
  float: right;
  margin-top: 23px;
  font-size: 18px; }
  #sort-by .method {
    font-size: 18px;
    padding: 0;
    width: inherit;
    border: none;
    font-weight: bold;
    -webkit-appearance: none;
    -moz-appearance: none; }
    #sort-by .method:hover {
      cursor: pointer; }
  @media only screen and (max-width: 767px) {
    #sort-by {
      display: none; } }

#results-loop {
  margin-top: 20px; }
  @media only screen and (max-width: 767px) {
    #results-loop {
      margin-top: 0; } }
  #results-loop .premium {
    position: relative;
    border-color: #efba67; }
    #results-loop .premium::after {
      content: "§";
      font-family: Arial;
      position: absolute;
      top: 0;
      right: 30px;
      padding: 8px 15px 10px;
      background: #efba67;
      color: white;
      font-weight: bold;
      font-size: 25px; }
    #results-loop .premium:hover {
      border-color: #4071ff; }
      #results-loop .premium:hover::after {
        background: #4071ff; }
  #results-loop .results-baner {
    padding: 65px 0;
    margin-bottom: 20px;
    background: url(../img/results-baner.jpg) no-repeat center;
    background-size: cover;
    color: white;
    font-weight: bold; }
    #results-loop .results-baner h3 {
      font-size: 27px;
      line-height: 37px;
      margin-top: 20px; }
    #results-loop .results-baner .button {
      margin-top: 30px;
      font-size: 18px;
      border-color: white;
      border-width: 2px;
      max-width: 280px; }
      #results-loop .results-baner .button:hover {
        background: white;
        color: #3bae3b; }
    @media only screen and (max-width: 767px) {
      #results-loop .results-baner {
        padding: 20px 0;
        text-align: center; }
        #results-loop .results-baner img {
          max-width: 100px; }
        #results-loop .results-baner .button {
          margin: 30px auto 0; } }

.results-loop-item {
  margin-bottom: 20px;
  border: 2px solid #dddddd; }
  .results-loop-item:hover {
    border-color: #4071ff;
    box-shadow: 0 0 10px #96bcf4; }
  .results-loop-item .picture {
    display: block;
    margin: 30px; }
    .results-loop-item .picture img {
      width: 130px;
      height: 130px;
      object-fit: cover; }
      @media screen and (max-width: 1035px) {
        .results-loop-item .picture img {
          width: 90px;
          height: 90px; } }
      @media only screen and (max-width: 767px) {
        .results-loop-item .picture img {
          width: 130px;
          height: 130px;
          margin-left: 10px; } }
      @media screen and (max-width: 482px) {
        .results-loop-item .picture img {
          width: 90px;
          height: 90px;
          margin-left: 0; } }
    @media only screen and (max-width: 767px) {
      .results-loop-item .picture {
        margin: 40px 0 0 15px; } }
  .results-loop-item .name {
    display: block;
    margin: 30px 70px 20px 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px; }
    @media only screen and (max-width: 767px) {
      .results-loop-item .name {
        margin-top: 50px;
        margin-right: 5px;
        font-size: 20px;
        line-height: 26px; } }
  .results-loop-item .category, .results-loop-item .rate {
    display: inline-block; }
  .results-loop-item .category {
    font-weight: bold; }
  .results-loop-item .rating {
    border-left: 1px solid #cccccc;
    margin-left: 10px;
    padding-left: 15px;
    display: inline;
    color: #ff9540;
    font-weight: bold; }
  .results-loop-item .info {
    color: #666666;
    margin: 15px 0; }
    .results-loop-item .info p {
      font-size: 16px; }
    .results-loop-item .info span {
      color: black; }
    @media only screen and (max-width: 767px) {
      .results-loop-item .info {
        padding-right: 15px; } }
  .results-loop-item .actions {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    margin-right: 32px; }
    @media only screen and (max-width: 767px) {
      .results-loop-item .actions {
        margin: 15px;
        text-transform: capitalize;
        justify-content: space-around; } }
  .results-loop-item .button {
    font-weight: bold;
    padding: 15px 25px;
    display: inline-block;
    width: inherit; }
    @media only screen and (max-width: 767px) {
      .results-loop-item .button {
        width: 50%;
        padding: 15px 0; } }
  .results-loop-item .mail {
    color: #4071ff;
    background: #cadeff; }
    .results-loop-item .mail:hover {
      color: white;
      background: #4071ff; }
  .results-loop-item .tel {
    margin-left: 10px;
    background: #e5e5e5;
    color: black; }
    .results-loop-item .tel:hover {
      background: lightgrey; }
    @media only screen and (max-width: 767px) {
      .results-loop-item .tel:last-child {
        display: none; } }
  @media only screen and (max-width: 767px) {
    .results-loop-item .row {
      display: flex; }
      .results-loop-item .row > div {
        width: auto;
        flex: 1; } }

@media only screen and (max-width: 767px) {
  .profile-page .main {
    padding-top: 50px; }
  .profile-page #dolacz-do-spolecznosci {
    display: none; }
  .profile-page aside {
    display: none; } }

.dolacz-do-spolecznosci-small {
  margin: 20px 0;
  font-weight: bold; }
  .dolacz-do-spolecznosci-small .button {
    margin-top: 20px; }

#profile-header {
  position: relative;
  padding-top: 200px;
  background: url(../img/profile-header.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: white; }
  @media only screen and (max-width: 767px) {
    #profile-header {
      padding-top: 100px;
      background-size: auto 240px;
      background-position: top center;
      color: #000; } }
  #profile-header .profile-photo {
    position: absolute;
    width: 100%;
    max-width: 220px;
    bottom: -35px;
    background: #fff; }
    #profile-header .profile-photo img {
      border: 14px solid white;
      outline-style: solid;
      outline-color: #cccccc;
      outline-width: thin;
      display: block;
      width: 220px;
      height: 220px;
      object-fit: contain; }
      @media screen and (min-width: 768px) and (max-width: 934px) {
        #profile-header .profile-photo img {
          width: 180px;
          height: 180px; } }
    @media only screen and (max-width: 767px) {
      #profile-header .profile-photo {
        position: inherit;
        margin: -40px auto 40px; } }
  #profile-header .profile-info {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 30px; }
    #profile-header .profile-info .fa-check-circle {
      font-size: 25px;
      margin-bottom: 10px;
      margin-left: 20px; }
    #profile-header .profile-info .localization {
      opacity: .8;
      margin-top: 20px;
      font-size: 18px; }
    @media only screen and (max-width: 767px) {
      #profile-header .profile-info {
        font-size: 32px;
        margin-top: 20px;
        margin-bottom: 0;
        text-align: center; }
        #profile-header .profile-info h1 {
          font-size: 28px; }
        #profile-header .profile-info .fa-check-circle {
          display: none; } }
  #profile-header .options {
    float: right;
    font-weight: bold;
    margin-top: 20px;
    color: inherit; }
    @media only screen and (max-width: 767px) {
      #profile-header .options {
        display: none; } }

.details-line {
  background: white;
  box-shadow: 0 6px 6px #e5e5e5;
  padding: 15px 0;
  font-size: 18px; }
  .details-line .category {
    font-weight: bold;
    color: #4071ff;
    display: inline-block;
    border-right: 1px solid #ccc;
    padding: 10px 20px 10px 0;
    margin-right: 15px; }
  .details-line .rating {
    display: inline;
    color: #17181c; }
    .details-line .rating .rate-count {
      color: #ff9540;
      font-weight: bold; }
  .details-line .buttons {
    display: inline-block;
    float: right; }
    .details-line .buttons a {
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 5px;
      color: #17181c;
      padding: 8px 15px; }
  @media only screen and (max-width: 767px) {
    .details-line {
      display: none; } }

#profile-stats {
  margin-top: 40px;
  display: flex;
  justify-content: space-between; }
  #profile-stats .badges {
    width: 220px;
    text-align: center; }
    #profile-stats .badges img {
      margin-right: 10px; }
      #profile-stats .badges img:last-child {
        margin-right: 0; }
    @media only screen and (max-width: 767px) {
      #profile-stats .badges {
        flex: 1; } }
  #profile-stats .stats {
    display: flex; }
    #profile-stats .stats__elem {
      padding: 0 27px; }
      #profile-stats .stats__elem h5 {
        font-weight: normal; }
      #profile-stats .stats__elem .count {
        font-weight: bold;
        margin-top: 10px;
        font-size: 18px; }
    @media only screen and (max-width: 767px) {
      #profile-stats .stats {
        margin-bottom: 20px;
        flex: 3;
        flex-direction: column; }
        #profile-stats .stats__elem {
          margin: 2px 0; }
          #profile-stats .stats__elem .count, #profile-stats .stats__elem h5 {
            display: inline-block; } }

aside {
  margin-top: 30px; }

.profile-buttons {
  margin-bottom: 25px; }
  .profile-buttons a {
    height: 54px;
    display: block;
    padding: 18px;
    margin: 10px 0;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    box-shadow: 0 6px 6px #eaeaea; }
    .profile-buttons a i {
      float: left;
      opacity: .5; }
    .profile-buttons a:hover {
      cursor: pointer;
      opacity: .95; }
  .profile-buttons__show-phone {
    color: white;
    background: linear-gradient(to right, #17181c, #3a3a3c); }
    .profile-buttons__show-phone .cover span {
      background: white;
      color: #17181c;
      border-radius: 5px;
      margin-left: 10px;
      padding: 0 10px; }
  .profile-buttons__send-message {
    background: linear-gradient(to right, #3bae3b, #58ba58);
    color: white; }
  .profile-buttons__price-list {
    border: 1px solid #17181c;
    padding: 16px;
    color: #17181c; }

#profile-dodaj-komentarz {
  height: inherit !important; }
  @media only screen and (max-width: 767px) {
    #profile-dodaj-komentarz .submit-alert {
      display: none; }
    #profile-dodaj-komentarz .button {
      margin-top: 20px; } }

#profile-komentarze .elements {
  padding: 0;
  margin: 0; }

#profile-komentarze .comment {
  margin-bottom: 20px;
  display: flex; }
  #profile-komentarze .comment .picture {
    min-width: 75px;
    margin-right: 30px; }
    #profile-komentarze .comment .picture img {
      max-width: 100%;
      object-fit: cover; }
    @media only screen and (max-width: 767px) {
      #profile-komentarze .comment .picture {
        min-width: 55px; } }
  #profile-komentarze .comment .rating a {
    font-weight: bold; }
  #profile-komentarze .comment__content {
    border: 1px solid #cccccc;
    padding: 20px;
    line-height: 24px;
    font-size: 18px; }

#profile-qa .elements {
  padding: 0; }

#profile-qa .qa {
  margin-bottom: 20px; }
  #profile-qa .qa span {
    color: #888888; }
  #profile-qa .qa .question {
    position: relative;
    margin-right: 15%;
    padding: 10px 20px 55px 20px;
    background: #eeeeee;
    z-index: -1; }
    #profile-qa .qa .question .picture {
      background-size: cover;
      background-position: center center;
      position: absolute;
      bottom: -40px;
      left: 20px;
      width: 80px;
      height: 80px;
      border: 5px solid white; }
  #profile-qa .qa .answer {
    margin-top: -20px;
    margin-left: 15%;
    padding: 20px;
    border: 1px solid #cccccc;
    background: white; }
    @media only screen and (max-width: 767px) {
      #profile-qa .qa .answer {
        margin-left: 30%; } }

@media only screen and (max-width: 767px) {
  #profile-qa {
    border-bottom: none; }
    #profile-qa .see-more span {
      border-top: none; } }

#podobni-w-okolicy {
  padding: 0 15px;
  margin-bottom: 40px; }
  #podobni-w-okolicy a {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 20px; }
  #podobni-w-okolicy .person {
    padding: 15px; }
    #podobni-w-okolicy .person:nth-of-type(1n + 5) {
      border-top: 1px solid #cccccc; }
    #podobni-w-okolicy .person:nth-of-type(1n + 1) {
      border-right: 1px solid #cccccc; }
    #podobni-w-okolicy .person:nth-of-type(4n + 4) {
      border-right: none; }
    #podobni-w-okolicy .person:hover .picture img {
      transform: scale3d(0.9, 0.9, 0.9); }
  #podobni-w-okolicy .person-inner {
    display: flex;
    align-items: center; }
    #podobni-w-okolicy .person-inner .picture {
      display: block; }
      #podobni-w-okolicy .person-inner .picture img {
        width: 70px;
        height: 70px;
        object-fit: cover;
        transition: .5s; }
    #podobni-w-okolicy .person-inner .info {
      padding-top: 12px;
      padding-left: 15px; }
      #podobni-w-okolicy .person-inner .info p {
        margin-top: 5px; }
    #podobni-w-okolicy .person-inner .rate {
      color: #ff9540;
      font-weight: bold; }
  @media only screen and (max-width: 767px) {
    #podobni-w-okolicy {
      display: none; } }

.register-page #dolacz-do-spolecznosci {
  display: none; }

#register-heading {
  padding-top: 40px; }
  #register-heading .steps {
    display: flex;
    justify-content: center;
    padding: 40px 0 50px;
    border-bottom: 2px solid #e5e5e5; }
    #register-heading .steps .step {
      width: 200px;
      text-align: center; }
      #register-heading .steps .step .icon {
        position: relative;
        background: #e5e5e5;
        border-radius: 35px;
        height: 70px;
        width: 70px;
        margin: auto; }
        #register-heading .steps .step .icon::after {
          content: "";
          position: absolute;
          top: 34px;
          right: -130px;
          width: 130px;
          height: 2px;
          background: #e5e5e5; }
      #register-heading .steps .step h6 {
        margin-top: 20px; }
      #register-heading .steps .step:nth-child(1) .icon img {
        margin-top: 18px; }
      #register-heading .steps .step:nth-child(2) .icon img {
        margin-top: 15px;
        margin-left: -1px; }
      #register-heading .steps .step:nth-child(3) .icon img {
        margin-top: 22px;
        margin-left: 2px; }
      #register-heading .steps .step:nth-child(4) .icon img {
        margin-top: 15px; }
      #register-heading .steps .step:last-child .icon::after {
        display: none; }
    #register-heading .steps .current .icon {
      background: #4071ff; }
    #register-heading .steps .current h6 {
      color: #4071ff; }
    #register-heading .steps .completed .icon {
      background: #3bae3b; }
      #register-heading .steps .completed .icon::after {
        background: #3bae3b; }
  @media only screen and (max-width: 767px) {
    #register-heading {
      display: none; } }

.step-section {
  padding-bottom: 80px; }
  .step-section .step-title {
    margin: 50px 0 70px;
    line-height: 36px; }
    @media only screen and (max-width: 767px) {
      .step-section .step-title {
        margin: 40px 0; } }

.register-fist-step #double-cta .cta {
  margin: 0 20px; }
  .register-fist-step #double-cta .cta .icon img {
    max-width: 90px; }
  @media only screen and (max-width: 767px) {
    .register-fist-step #double-cta .cta {
      margin: 0;
      margin-bottom: 20px; } }

.register-second-step p,
.register-three-step p,
.register-four-step p {
  padding-bottom: 40px; }

.register-nav {
  margin-top: 40px;
  display: flex;
  justify-content: space-between; }
  .register-nav a {
    color: #888888;
    font-weight: bold;
    margin-top: 15px; }
  .register-nav .button {
    width: 230px;
    padding: 12px 0;
    font-size: 16px;
    border-radius: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    outline: none;
    background: #3bae3b; }
  @media only screen and (max-width: 767px) {
    .register-nav {
      margin-top: 20px;
      flex-direction: column-reverse;
      text-align: center; }
      .register-nav .button {
        width: 100%;
        margin: 20px auto 0; } }

.register-user-details .heading h3 {
  margin-bottom: 0; }

.register-user-details .heading p {
  margin: 30px 0;
  text-align: center;
  color: #444444;
  font-size: 16px; }
  @media only screen and (max-width: 767px) {
    .register-user-details .heading p {
      font-weight: normal; } }

.articles-page h1 {
  color: #fff; }

.articles-page aside {
  margin: 0; }

@media only screen and (max-width: 767px) {
  .articles-page #dolacz-do-spolecznosci {
    display: none; } }

.related-posts-top .row > div {
  padding: 0; }

.related-posts-top__left {
  height: 100%; }
  .related-posts-top__left .title h1, .related-posts-top__left .related-posts-top__baner .title h1 {
    color: white;
    text-shadow: black 0.1em 0.1em 0.2em; }
  .related-posts-top__left .related-posts-top__baner {
    height: 100%; }
    .related-posts-top__left .related-posts-top__baner__inner {
      padding: 65px; }
      @media only screen and (max-width: 980px) {
        .related-posts-top__left .related-posts-top__baner__inner {
          padding: 20px; } }
    .related-posts-top__left .related-posts-top__baner .category {
      font-size: 18px; }
    .related-posts-top__left .related-posts-top__baner .title {
      font-size: 36px;
      line-height: 60px; }
      @media only screen and (max-width: 980px) {
        .related-posts-top__left .related-posts-top__baner .title {
          font-size: 32px;
          line-height: 40px; } }
    .related-posts-top__left .related-posts-top__baner .post-meta {
      font-size: 18px; }

.related-posts-top__right {
  border-left: 5px solid #fff; }
  .related-posts-top__right .related-posts-top__baner:last-child {
    border-top: 5px solid #fff; }

.related-posts-top__baner {
  display: flex;
  position: relative; }
  .related-posts-top__baner__img img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: -1; }
  .related-posts-top__baner__img::after {
    position: absolute;
    content: "";
    background: linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.6));
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 1;
    transition: .5s; }
  .related-posts-top__baner__inner {
    display: flex;
    padding: 40px;
    padding-top: 70px; }
    @media only screen and (max-width: 980px) {
      .related-posts-top__baner__inner {
        padding: 20px; } }
  .related-posts-top__baner .content {
    align-self: flex-end;
    margin-top: 20px; }
  .related-posts-top__baner .category {
    display: inline-block;
    padding: 10px;
    background: #fff;
    color: #000;
    font-size: 16px;
    margin-bottom: 20px; }
  .related-posts-top__baner .title {
    display: block;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    max-width: 67%; }
    @media only screen and (max-width: 980px) {
      .related-posts-top__baner .title {
        max-width: inherit;
        font-size: 22px; } }

@media only screen and (max-width: 767px) {
  .related-posts-top {
    display: none; } }

.post-meta {
  font-size: 16px; }
  .post-meta .author, .post-meta .comments, .post-meta .date {
    display: inline-block;
    color: #fff;
    font-weight: bold; }
  .post-meta .author {
    display: inline-block;
    border-right: 1px solid #726f71;
    padding-right: 30px;
    padding-bottom: 10px; }
    .post-meta .author img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-bottom: -17px;
      margin-right: 20px;
      border: 1px solid black; }
  .post-meta .comments {
    margin: 0 15px; }
    .post-meta .comments span {
      margin: 0 10px; }
  @media only screen and (max-width: 767px) {
    .post-meta .author {
      padding-right: 0;
      border-right: none;
      width: 100%; }
    .post-meta .comments {
      margin-top: 20px;
      margin-left: 0; } }

.main-section {
  background: linear-gradient(to right, #fff 50%, #f4f4f4 50%); }

#search-problem {
  padding: 60px 0 40px;
  padding-right: 60px; }
  #search-problem form {
    display: flex; }
  #search-problem input, #search-problem button {
    font-size: 18px;
    font-weight: bold;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25); }
    @media only screen and (max-width: 767px) {
      #search-problem input, #search-problem button {
        margin-top: 20px; } }
  #search-problem input {
    flex: 6;
    padding: 15px 20px;
    border: none; }
  #search-problem button {
    border: none;
    flex: 2; }
  @media only screen and (max-width: 767px) {
    #search-problem {
      margin-top: 20px;
      padding: 20px 0; }
      #search-problem form {
        flex-wrap: wrap; } }

.articles-categories {
  margin: 30px 0 10px; }
  .articles-categories__list a {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
    color: #888888;
    transition: .5s; }
    .articles-categories__list a:hover {
      color: #000; }
  @media only screen and (max-width: 767px) {
    .articles-categories {
      display: none; } }

.articles-loop__item {
  padding: 30px 0;
  padding-right: 60px;
  border-bottom: 1px solid #ddd; }
  @media only screen and (max-width: 767px) {
    .articles-loop__item {
      padding-right: 0; } }
  .articles-loop__item:last-child {
    border-bottom: none; }
  .articles-loop__item .post-image {
    display: block;
    width: 250px;
    height: 170px;
    max-width: 100%;
    max-width: 100%;
    position: relative; }
    @media screen and (min-width: 767px) and (max-width: 980px) {
      .articles-loop__item .post-image {
        height: 120px; } }
    .articles-loop__item .post-image::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #4071ff;
      opacity: 0;
      transition: .5s; }
    .articles-loop__item .post-image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    @media only screen and (max-width: 767px) {
      .articles-loop__item .post-image {
        width: 100%;
        height: 300px;
        object-fit: cover; } }
  .articles-loop__item .category {
    display: block;
    color: #888888;
    font-weight: bold; }
  .articles-loop__item .title {
    display: block;
    margin: 15px 0;
    font-size: 24px;
    font-weight: bold;
    color: #000; }
  .articles-loop__item .author, .articles-loop__item .comments, .articles-loop__item .date {
    color: #000; }
    @media only screen and (max-width: 767px) {
      .articles-loop__item .author, .articles-loop__item .comments, .articles-loop__item .date {
        display: none; } }
  .articles-loop__item .author {
    border-color: #cccccc; }
  @media screen and (min-width: 767px) and (max-width: 980px) {
    .articles-loop__item .date {
      margin-top: 20px; } }
  .articles-loop__item article {
    margin: 25px 0; }
    @media only screen and (max-width: 767px) {
      .articles-loop__item article {
        display: none; } }
  .articles-loop__item .read-more {
    font-size: 18px;
    font-weight: bold;
    color: #4071ff; }
    .articles-loop__item .read-more svg {
      margin-left: 10px;
      margin-bottom: -1px; }
  .articles-loop__item:hover .post-image::after {
    opacity: .8; }
  @media only screen and (max-width: 767px) {
    .articles-loop__item .row > div {
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 40px; }
      .articles-loop__item .row > div .title {
        margin-top: -20px;
        line-height: 30px; }
      .articles-loop__item .row > div a {
        flex: 100%; }
      .articles-loop__item .row > div .read-more {
        position: absolute;
        bottom: 0; } }

.articles-loop .featured-post {
  display: none; }
  @media only screen and (max-width: 767px) {
    .articles-loop .featured-post {
      display: block; } }

@media only screen and (max-width: 767px) {
  .articles-loop .articles-loop__item .post-image {
    width: 100%;
    height: 100%; } }

#articles-pagination .pages {
  float: right; }

.article-page h1 {
  color: white;
  text-shadow: black 0.1em 0.1em 0.2em; }
  .article-page h1 a {
    color: white;
    text-shadow: black 0.1em 0.1em 0.2em; }

.article-page #profile-komentarze {
  border-bottom: none;
  padding-bottom: 0; }

@media only screen and (max-width: 767px) {
  .article-page .main {
    padding-top: 68px; } }

.article-page #dolacz-do-spolecznosci {
  display: none; }

.post-baner {
  display: flex;
  width: 100%;
  height: 60vh;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .post-baner {
      height: inherit; } }
  .post-baner__img img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: -1; }
  .post-baner__img::after {
    position: absolute;
    content: "";
    background: linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.6));
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .post-baner__inner {
    display: flex;
    height: 60vh; }
    @media only screen and (max-width: 767px) {
      .post-baner__inner {
        height: inherit; } }
  .post-baner .content {
    margin-bottom: 10vh;
    align-self: flex-end;
    margin-top: 20px; }
    @media only screen and (max-width: 767px) {
      .post-baner .content {
        margin-bottom: 40px; } }
  .post-baner .category {
    display: inline-block;
    padding: 10px;
    background: #ff943e;
    color: #fff;
    font-size: 18px;
    margin-bottom: 20px; }
    @media only screen and (max-width: 767px) {
      .post-baner .category {
        margin-top: 60px; } }
  .post-baner .title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    color: #fff;
    max-width: 67%; }
    @media only screen and (max-width: 767px) {
      .post-baner .title {
        max-width: inherit;
        font-size: 32px;
        line-height: 40px; } }
  .post-baner .post-meta {
    font-size: 18px; }
  .post-baner .scroll-down-below {
    position: absolute;
    bottom: 30px;
    width: 100%;
    left: 0;
    margin-top: -50px;
    text-align: center;
    color: white;
    font-weight: bold; }
    .post-baner .scroll-down-below:hover {
      cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .post-baner .scroll-down-below {
        display: none; } }

.share-post {
  margin-top: 60px;
  text-align: center; }
  .share-post .loveit {
    padding-bottom: 33px;
    border-bottom: 1px solid #ccc;
    transition: .5s; }
    .share-post .loveit i {
      font-size: 50px;
      color: #f53737; }
    .share-post .loveit:hover {
      border-bottom-color: #f53737; }
  .share-post .socials {
    padding-top: 8px; }
    .share-post .socials a {
      margin: 25px 0;
      display: block; }
      .share-post .socials a:hover:nth-child(1) i {
        color: #3b5999; }
      .share-post .socials a:hover:nth-child(2) i {
        color: #55acee; }
      .share-post .socials a:hover:nth-child(3) i {
        color: #dd4b39; }
      .share-post .socials a:hover:nth-child(4) i {
        color: #0084ff; }
    .share-post .socials i {
      font-size: 35px;
      color: #888888;
      transition: .5s; }

.share-post-horizontall {
  margin-top: 0;
  margin-bottom: 20px;
  display: flex; }
  .share-post-horizontall .loveit {
    padding-top: 5px;
    padding-bottom: 10px;
    padding-right: 20px;
    margin-right: 10px;
    border-bottom: none;
    border-right: 1px solid #ccc; }
    .share-post-horizontall .loveit i {
      font-size: 35px; }
  .share-post-horizontall .socials {
    display: flex; }
    .share-post-horizontall .socials i {
      padding-top: 5px;
      font-size: 24px; }
    .share-post-horizontall .socials a {
      margin: 0 10px; }

.article-content {
  padding: 40px 15px; }
  .article-content article h1 {
    font-size: 26px;
    line-height: 36px;
    font-weight: bold;
    color: #383838; }
  .article-content article h2 {
    font-size: 24px;
    line-height: 24px;
    margin: 60px 0 18px; }
  .article-content article h3 {
    font-size: 20px;
    line-height: 24px;
    margin: 60px 0 18px; }
  .article-content article h4 {
    font-size: 18px;
    line-height: 20px;
    margin: 40px 0 0; }
  .article-content article strong {
    font-weight: bold; }
  .article-content article p {
    color: #383838;
    margin: 18px 0;
    font-size: 17px;
    line-height: 30px;
    text-align: justify; }
    .article-content article p::first-letter {
      font-size: 24px; }
  .article-content article ul, .article-content article ol {
    margin-left: 20px;
    padding-left: 15px;
    list-style: disc;
    font-weight: lighter; }
    .article-content article ul li, .article-content article ol li {
      margin: 10px 0;
      padding-left: 5px;
      font-size: 18px;
      line-height: 30px; }
      .article-content article ul li::first-letter, .article-content article ol li::first-letter {
        font-size: inherit; }
      .article-content article ul li p, .article-content article ol li p {
        margin: 0;
        font-size: 16px;
        line-height: 20px;
        text-align: inherit; }
        .article-content article ul li p::first-letter, .article-content article ol li p::first-letter {
          font-size: inherit; }
  .article-content article ol {
    list-style: decimal; }
  .article-content article table tr td {
    padding: 10px;
    font-size: 15px;
    border: 1px solid gray; }
    .article-content article table tr td::first-letter {
      font-size: inherit; }
    .article-content article table tr td p {
      font-size: 15px;
      padding: 0;
      margin: 0;
      text-align: inherit; }
      .article-content article table tr td p::first-letter {
        font-size: inherit; }
  .article-content .article-meta {
    display: flex;
    margin-top: 40px;
    color: #888888; }
    .article-content .article-meta .source, .article-content .article-meta .tags {
      padding-bottom: 40px; }
    .article-content .article-meta .tags {
      margin-left: 40px; }
    .article-content .article-meta a {
      color: #000; }
  .article-content .author {
    padding-bottom: 30px;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6; }
    .article-content .author__img img {
      width: 70px;
      height: 70px;
      border: 1px solid black; }
    .article-content .author__name h4 {
      font-size: 20px;
      color: #000;
      margin: 15px 0 10px; }
    .article-content .author__name h5 {
      font-size: 18px;
      font-weight: normal;
      color: #000; }
    .article-content .author .link {
      margin-top: 30px;
      float: right;
      color: #4071ff;
      font-weight: bold; }
    @media only screen and (max-width: 767px) {
      .article-content .author .row {
        display: flex; }
        .article-content .author .row > div {
          width: auto; }
      .article-content .author .button {
        margin-top: 15px;
        margin-left: 15px; } }
  .article-content .comment__content span {
    font-weight: normal; }

@media only screen and (max-width: 767px) {
  #article-dodaj-komentarz .elements {
    padding-bottom: 0; }
  #article-dodaj-komentarz .heading {
    padding-bottom: 0; }
  #article-dodaj-komentarz .submit-alert {
    margin-right: 0px;
    margin-bottom: 10px; }
  #article-dodaj-komentarz textarea {
    margin-bottom: 10px; }
  #article-dodaj-komentarz .button {
    margin-left: 20px; } }

#section-podobne-artykuly {
  padding-top: 40px;
  border-top: 1px solid #F4F4F4; }

#podobne-artykuly {
  padding: 40px 0; }
  @media only screen and (max-width: 767px) {
    #podobne-artykuly .article {
      margin-bottom: 40px; } }
  #podobne-artykuly .article .image {
    display: block;
    height: 230px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
    #podobne-artykuly .article .image::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #4071ff;
      opacity: 0;
      transition: .5s; }
  #podobne-artykuly .article .info {
    border: 1px solid #ececec;
    border-top: none;
    background: white;
    padding: 20px;
    position: relative; }
    #podobne-artykuly .article .info p {
      margin-top: 8px;
      font-size: 16px;
      line-height: 24px; }
    #podobne-artykuly .article .info::after {
      content: "";
      position: absolute;
      left: -1px;
      bottom: 0;
      background: #4071ff;
      height: 2px;
      width: 0;
      transition: .5s; }
  #podobne-artykuly .article .title {
    display: block;
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    color: inherit; }
    #podobne-artykuly .article .title:hover {
      text-decoration: none; }
  #podobne-artykuly .article .category {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: #888888;
    margin: 15px 0; }
    #podobne-artykuly .article .category:hover {
      text-decoration: none; }
  #podobne-artykuly .article .more {
    color: #4071ff;
    font-size: 18px;
    display: block;
    font-weight: bold; }
    #podobne-artykuly .article .more svg {
      margin-left: 10px; }
  #podobne-artykuly .article:hover .image::after {
    opacity: .7; }
  #podobne-artykuly .article:hover .info::after {
    width: calc(100% + 2px); }

.not-found-page #dolacz-do-spolecznosci {
  display: none; }

#not-found {
  padding: 150px 0;
  background: url(../img/404.png) no-repeat;
  background-position: center 40%; }
  #not-found h1 {
    margin-top: 20px;
    font-size: 46px;
    font-weight: bold; }
  #not-found p {
    font-weight: bold; }
  #not-found .button {
    font-weight: bold;
    max-width: 270px;
    margin: auto;
    margin-top: 20px; }
  @media only screen and (max-width: 980px) {
    #not-found {
      padding: 100px 0; } }
  @media only screen and (max-width: 767px) {
    #not-found {
      padding: 50px 0;
      background-size: 45%;
      background-position-y: 50px;
      background-position-x: 90%; }
      #not-found h1 {
        line-height: 46px;
        font-size: 38px; } }

.newsletter-page #dolacz-do-spolecznosci {
  display: none; }

#newsletter-section {
  padding: 110px 0 90px; }
  @media only screen and (max-width: 767px) {
    #newsletter-section {
      padding: 40px 0; } }

#newsletter-form-big {
  margin-top: 55px; }
  #newsletter-form-big input {
    flex: 9; }
    @media only screen and (max-width: 767px) {
      #newsletter-form-big input {
        padding: 15px; } }
  #newsletter-form-big .button {
    flex: 3; }

#newsletter-features {
  background: #eeeeee;
  padding: 90px 0 80px; }
  @media only screen and (max-width: 767px) {
    #newsletter-features {
      padding: 40px 0; } }

.flex-features {
  margin-top: 85px;
  display: flex; }
  .flex-features .feature {
    flex: 1;
    text-align: center; }
    .flex-features .feature .img {
      height: 70px; }
    .flex-features .feature p {
      font-size: 16px;
      max-width: 300px;
      margin: auto; }
    .flex-features .feature a {
      color: #4071ff;
      font-size: 13px;
      display: block;
      margin-top: 10px; }
  @media only screen and (max-width: 767px) {
    .flex-features {
      flex-direction: column; }
      .flex-features .feature {
        margin-bottom: 20px; }
        .flex-features .feature:last-child {
          margin-bottom: 0; } }

.forum #dolacz-do-spolecznosci {
  display: none; }

#forum-search {
  margin-top: 50px; }
  #forum-search .button-green {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
    padding: 21px 0;
    transition: .5s;
    font-size: 18px; }
    #forum-search .button-green:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05); }
    @media only screen and (max-width: 767px) {
      #forum-search .button-green {
        display: none; } }
  #forum-search .link {
    margin-top: 45px;
    font-size: 18px;
    display: block; }
  #forum-search h3 {
    font-size: 20px; }
    #forum-search h3 small {
      font-weight: normal;
      font-size: 16px;
      color: gray; }
  @media only screen and (max-width: 767px) {
    #forum-search {
      margin: 40px 0; }
      #forum-search .button {
        padding: 15px; } }

#forum-search-form input {
  flex: 8; }
  @media only screen and (max-width: 767px) {
    #forum-search-form input {
      padding: 15px;
      margin-bottom: 20px; } }

#forum-search-form .button {
  flex: 3; }

#forum-categories {
  margin: 30px 0; }
  #forum-categories ul li a {
    display: block;
    position: relative;
    border-bottom: 1px solid #e5e5e5;
    padding: 20px 5px 20px 70px;
    font-weight: bold;
    font-size: 18px; }
    #forum-categories ul li a img {
      position: absolute;
      top: 10px;
      left: 10px; }
    #forum-categories ul li a:hover {
      background: #f5f8ff; }
  #forum-categories ul li:last-child a {
    border-bottom: none; }

.choose-category {
  margin-top: 40px; }
  .choose-category h3 {
    margin: 0; }
    @media only screen and (max-width: 767px) {
      .choose-category h3 {
        font-size: 20px; } }

.forum-topics-page .pagination {
  margin-top: 40px; }

/* Topics in category */
#forum-category h3 {
  margin: 40px 0; }
  #forum-category h3 small {
    color: #7d7d7d;
    font-size: 14px;
    font-weight: normal; }

#forum-category .topics .topic {
  display: grid;
  grid-template-columns: 55px auto 170px 100px;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5; }
  #forum-category .topics .topic__author-avatar img {
    width: 35px;
    border-radius: 35px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.17); }
  @media only screen and (max-width: 767px) {
    #forum-category .topics .topic__author-avatar {
      display: none; } }
  #forum-category .topics .topic__details__title {
    font-weight: bold;
    font-size: 16px; }
  #forum-category .topics .topic__details__meta {
    margin-top: 8px;
    font-size: 14px;
    color: #7b7b7b; }
  @media only screen and (max-width: 767px) {
    #forum-category .topics .topic__details {
      padding: 10px 5px 10px 0; } }
  #forum-category .topics .topic__answer {
    font-size: 14px; }
    #forum-category .topics .topic__answer__date {
      color: #7b7b7b;
      margin-top: 10px; }
    @media only screen and (max-width: 767px) {
      #forum-category .topics .topic__answer {
        display: none; } }
  #forum-category .topics .topic__answers {
    font-size: 14px;
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      #forum-category .topics .topic__answers {
        background: lightgray;
        color: gray;
        padding-left: 10px; }
        #forum-category .topics .topic__answers::after {
          margin-left: 5px;
          content: " odpowiedzi"; } }
  @media only screen and (max-width: 767px) {
    #forum-category .topics .topic {
      grid-template-columns: auto 30%;
      padding: 0; }
      #forum-category .topics .topic:first-child {
        display: none; } }

#forum-pagination {
  margin-top: 40px; }

/* Single topic */
#forum-topic .topic {
  margin-top: 20px;
  padding: 20px 0 100px;
  display: flex;
  border-top: 1px solid #e5e5e5; }
  @media only screen and (max-width: 767px) {
    #forum-topic .topic {
      padding: 20px 0; } }
  #forum-topic .topic__author-avatar {
    margin-right: 25px; }
    #forum-topic .topic__author-avatar img {
      margin-top: 40px;
      width: 40px;
      border-radius: 40px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.17); }
      @media only screen and (max-width: 767px) {
        #forum-topic .topic__author-avatar img {
          margin-top: 0; } }
  #forum-topic .topic__content h3 {
    margin: 0; }
  #forum-topic .topic__content .button {
    margin-top: 65px;
    max-width: 160px;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.15);
    padding: 10px; }
    #forum-topic .topic__content .button:hover {
      box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.05);
      opacity: .9; }
    @media only screen and (max-width: 767px) {
      #forum-topic .topic__content .button {
        margin-top: 40px; } }

#forum-topic .meta {
  color: #7b7b7b;
  font-size: 15px;
  margin: 30px 0;
  line-height: 20px; }
  @media only screen and (max-width: 767px) {
    #forum-topic .meta {
      margin: 20px 0; } }

#forum-topic article {
  color: #7b7b7b; }
  #forum-topic article p {
    font-size: 15px;
    line-height: 25px; }
  #forum-topic article a {
    color: #4071ff; }

#forum-topic .answers-heading {
  padding: 20px 0;
  font-weight: normal;
  font-size: 16px;
  border-top: 1px solid #e5e5e5; }

#forum-topic .answers {
  border-top: 1px solid #e5e5e5; }

#forum-topic .answer {
  display: flex;
  padding: 25px 0 30px;
  border-bottom: 1px solid #e5e5e5; }
  #forum-topic .answer__author-avatar {
    margin-right: 25px; }
    #forum-topic .answer__author-avatar img {
      width: 40px;
      border-radius: 40px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.17); }
  #forum-topic .answer__content .meta {
    margin-top: 15px; }
  #forum-topic .answer__content .blockquote {
    position: relative;
    margin: 30px 0; }
    #forum-topic .answer__content .blockquote__source {
      padding: 0 30px 10px 45px; }
    #forum-topic .answer__content .blockquote__content {
      padding: 8px 30px 8px 45px;
      background: #ededed; }
    #forum-topic .answer__content .blockquote::before {
      position: absolute;
      content: "";
      left: -23px;
      top: 0;
      width: 54px;
      height: 40px;
      background: url(../img/q.png); }
  #forum-topic .answer .vote {
    margin-top: 30px; }
    @media only screen and (max-width: 767px) {
      #forum-topic .answer .vote {
        display: none; } }

/* ASIDE */
.forum-aside {
  margin-top: 42px; }
  .forum-aside h5 {
    padding-left: 30px;
    padding-bottom: 25px;
    border-bottom: 1px solid #ccc;
    position: relative; }
    .forum-aside h5::before {
      content: "";
      position: absolute;
      width: 12px;
      height: 3px;
      background: #3bae3b;
      left: 30px;
      bottom: -1px; }
  @media only screen and (max-width: 767px) {
    .forum-aside {
      display: none; } }

.najnowsze-odpowiedzi {
  margin-bottom: 20px; }
  .najnowsze-odpowiedzi .conversation {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dotted #ccc; }
    .najnowsze-odpowiedzi .conversation:last-child {
      border-bottom: none; }
    .najnowsze-odpowiedzi .conversation .question {
      font-size: 14px;
      line-height: 24px;
      padding: 25px 20px 45px 20px;
      background: #dddddd;
      width: calc(100% - 30px); }
    .najnowsze-odpowiedzi .conversation .answer {
      padding: 20px;
      background: white;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.07);
      width: calc(100% - 30px);
      margin-top: -20px;
      margin-left: 30px; }
      .najnowsze-odpowiedzi .conversation .answer p {
        font-size: 14px;
        line-height: 24px; }
      .najnowsze-odpowiedzi .conversation .answer .author {
        font-size: 13px; }
        .najnowsze-odpowiedzi .conversation .answer .author a {
          text-decoration: underline; }

.article-create-page #dolacz-do-spolecznosci {
  display: none; }

.article-create-page h2 {
  margin: 10px 0 20px; }
  @media only screen and (max-width: 767px) {
    .article-create-page h2 {
      margin-top: 40px; } }

#article-create-form {
  margin: 50px 50px 80px 0; }
  #article-create-form input, #article-create-form select, #article-create-form textarea {
    width: 100%;
    border: 1px solid #c3c3c3;
    padding: 12px 15px;
    font-size: 16px; }
  #article-create-form select {
    padding: 11px 15px; }
  #article-create-form textarea {
    height: 360px; }
    @media only screen and (max-width: 767px) {
      #article-create-form textarea {
        height: 260px; } }
  #article-create-form .button {
    display: inline;
    max-width: 230px;
    font-size: 16px;
    margin-top: 10px;
    padding: 12px; }
  @media only screen and (max-width: 767px) {
    #article-create-form {
      margin: 40px 0; }
      #article-create-form textarea {
        margin-top: -10px; } }
  #article-create-form .form-group {
    margin: 10px 0; }

.benefits-page .baner {
  padding: 110px 0 85px;
  background-image: url(../img/benefits-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white; }
  .benefits-page .baner h1 span {
    font-weight: bold;
    display: block; }
    .benefits-page .baner h1 span:nth-child(1) {
      font-size: 45px;
      line-height: 40px; }
    .benefits-page .baner h1 span:nth-child(2) {
      font-size: 214px;
      line-height: 125px;
      margin-left: -20px; }
    .benefits-page .baner h1 span:nth-child(3) {
      font-size: 77px;
      line-height: 110px; }
    .benefits-page .baner h1 span:nth-child(4) {
      font-size: 28px; }
    @media only screen and (max-width: 767px) {
      .benefits-page .baner h1 span:nth-child(1) {
        font-size: 38px;
        line-height: 80px; }
      .benefits-page .baner h1 span:nth-child(2) {
        font-size: 170px;
        line-height: 105px;
        margin-left: -20px; }
      .benefits-page .baner h1 span:nth-child(3) {
        font-size: 58px;
        line-height: 78px; }
      .benefits-page .baner h1 span:nth-child(4) {
        font-size: 22px;
        line-height: 40px; } }
  .benefits-page .baner img {
    margin-top: 83px; }
  .benefits-page .baner p {
    font-size: 22px;
    line-height: 30px;
    font-weight: bold; }
  .benefits-page .baner .button {
    margin-top: 90px;
    font-size: 20px;
    border: 2px solid white;
    max-width: 326px; }
  @media only screen and (max-width: 767px) {
    .benefits-page .baner {
      padding: 60px 0; }
      .benefits-page .baner p {
        margin-top: 20px;
        font-size: 22px;
        line-height: 36px; }
      .benefits-page .baner .button {
        margin-top: 40px;
        font-size: 24px;
        border: 2px solid white;
        max-width: 326px; } }

.benefits-page .bg-white {
  padding: 80px 0 60px; }
  .benefits-page .bg-white .heading {
    font-size: 36px;
    margin-bottom: 20px; }
  .benefits-page .bg-white p {
    font-size: 18px;
    line-height: 34px; }
  @media only screen and (max-width: 767px) {
    .benefits-page .bg-white {
      padding: 60px 0 40px; }
      .benefits-page .bg-white .heading {
        font-size: 24px; } }

.benefits-page .counters {
  background: #eee;
  padding: 60px 0; }
  .benefits-page .counters .heading {
    font-size: 24px;
    text-align: center; }
  @media only screen and (max-width: 767px) {
    .benefits-page .counters .feature {
      margin-bottom: 60px; }
      .benefits-page .counters .feature:last-child {
        margin-bottom: 0; } }

.benefits-page .benefits-section {
  padding: 60px 0 80px; }
  .benefits-page .benefits-section .heading {
    font-size: 24px;
    text-align: center; }
  @media only screen and (max-width: 767px) {
    .benefits-page .benefits-section .flex-features {
      margin-top: 30px; } }
  .benefits-page .benefits-section .feature {
    margin: 0 15px;
    padding: 40px 30px 35px;
    background: #eee; }
    .benefits-page .benefits-section .feature:first-child {
      margin-left: 0;
      color: white;
      background: #4071ff; }
    .benefits-page .benefits-section .feature:last-child {
      margin-right: 0; }
    @media only screen and (max-width: 767px) {
      .benefits-page .benefits-section .feature {
        margin: 20px; }
        .benefits-page .benefits-section .feature:first-child {
          margin-left: 20px; } }

.benefits-page #dolacz-do-spolecznosci {
  display: none; }

@media only screen and (max-width: 767px) {
  .benefits-page .main {
    padding-top: 65px; } }

.wyroznieni-prawnicy-page .baner {
  padding: 130px 0;
  background-image: url(../img/prawnicy-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: white;
  font-size: 36px; }
  @media only screen and (max-width: 767px) {
    .wyroznieni-prawnicy-page .baner {
      padding: 40px 0;
      background: none;
      color: #000;
      text-align: left;
      font-size: 24px;
      font-weight: bold; } }

.wyroznieni-prawnicy-page .bread-crumbs {
  margin-top: 20px; }

.wyroznieni-prawnicy-page .results-loop-item .actions {
  justify-content: flex-start; }
  @media only screen and (max-width: 767px) {
    .wyroznieni-prawnicy-page .results-loop-item .actions {
      justify-content: space-between; } }

.wyroznieni-prawnicy-page .results-loop-item .button {
  margin-right: 10px; }
  @media only screen and (max-width: 767px) {
    .wyroznieni-prawnicy-page .results-loop-item .button {
      margin-right: 0; } }

.logged header .right-menu li {
  display: flex; }
  .logged header .right-menu li a {
    display: flex;
    align-items: center; }
    .logged header .right-menu li a:hover {
      cursor: pointer; }
  .logged header .right-menu li:first-child {
    margin-top: 12px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    margin-right: 20px; }
  .logged header .right-menu li:last-child a i {
    margin-left: 10px; }

.logged header .right-menu__profile {
  padding-right: 0;
  border-right: none !important;
  margin-top: 0 !important;
  margin-right: 0 !important; }
  .logged header .right-menu__profile .dropdown-profile-toggle {
    position: relative; }
    .logged header .right-menu__profile .dropdown-profile-toggle .profile-alert {
      left: 20px;
      top: -10px;
      position: absolute;
      display: block;
      padding: 5px;
      background: #f53737;
      color: white;
      font-size: 14px;
      text-align: center;
      border-radius: 15px;
      height: 24px;
      width: 24px; }

.logged header .right-menu img {
  margin: 0 25px;
  display: inline;
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50px;
  height: 50px !important;
  object-fit: cover !important; }

.logged header .right-menu .dropdown-profile {
  display: none;
  background: #17181A;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 0;
  top: 50px;
  padding: 10px;
  min-width: 160px; }
  .logged header .right-menu .dropdown-profile a {
    display: block;
    padding: 10px 5px;
    font-size: 16px;
    text-align: right; }

#panel {
  height: 100vh;
  display: flex;
  flex-wrap: wrap; }
  #panel .aside-menu {
    padding-top: 125px;
    flex: 1;
    transition: .5s;
    background: #ececec; }
    #panel .aside-menu .menu-title {
      margin: 20px 0 15px;
      padding-left: 25px;
      font-size: 14px;
      color: #888888; }
    #panel .aside-menu nav a {
      margin-left: 2px;
      position: relative;
      padding: 20px 20px 20px 62px;
      font-size: 18px;
      display: block;
      border-bottom: 1px solid #cccccc; }
      #panel .aside-menu nav a i {
        left: 22px;
        position: absolute;
        transition: .5s; }
      #panel .aside-menu nav a:first-child {
        border-top: 1px solid #cccccc; }
      #panel .aside-menu nav a:hover i {
        color: #4071ff; }
    #panel .aside-menu nav .current {
      color: #4071ff;
      border-left: 3px solid #4071ff; }
    @media only screen and (max-width: 767px) {
      #panel .aside-menu {
        padding-top: 40px;
        position: fixed;
        width: 100%;
        z-index: 5000;
        margin-left: -100%;
        height: 100%; } }
  #panel .aside-menu-active {
    margin-left: 0; }
  #panel .main {
    margin-top: 150px;
    flex: 5;
    padding-top: 30px;
    padding-bottom: 80px; }
    @media only screen and (max-width: 767px) {
      #panel .main {
        margin-top: 70px; } }

@media only screen and (max-width: 767px) {
  #panel-profile h2 {
    text-align: center; } }

#panel-profile .form {
  margin-top: 40px; }
  #panel-profile .form .avatar {
    display: flex; }
    #panel-profile .form .avatar img {
      width: 35%;
      margin-right: 20px;
      height: 180px;
      object-fit: cover; }
    #panel-profile .form .avatar div {
      width: 65%; }
      #panel-profile .form .avatar div .file {
        position: relative; }
        #panel-profile .form .avatar div .file #select-avatar {
          position: absolute;
          left: 0;
          top: 50px;
          opacity: 0;
          margin-top: -50px; }
      #panel-profile .form .avatar div .button {
        width: 100%;
        padding: 10px 25px; }
      #panel-profile .form .avatar div p {
        max-width: 240px;
        font-size: 14px;
        color: #444; }
    @media only screen and (max-width: 767px) {
      #panel-profile .form .avatar {
        display: inherit;
        text-align: center; }
        #panel-profile .form .avatar img {
          margin-right: 0;
          min-width: 90%; } }
  #panel-profile .form h4 {
    margin-bottom: 20px; }
    @media only screen and (max-width: 767px) {
      #panel-profile .form h4 {
        text-align: center; } }

#panel-profile label {
  font-size: 16px;
  font-weight: bold; }

#panel-profile .submit {
  margin-top: 20px;
  float: right; }
  @media only screen and (max-width: 767px) {
    #panel-profile .submit {
      width: 100%;
      padding: 10px; } }

.note-editor .note-editing-area {
  font-family: 'Arial';
  font-size: 16px;
  letter-spacing: .2px; }
  .note-editor .note-editing-area p {
    font-size: 16px;
    margin: 20px 0 10px; }
    .note-editor .note-editing-area p i {
      font-style: italic; }
  .note-editor .note-editing-area ul {
    list-style: disc;
    margin-left: 15px; }
  .note-editor .note-editing-area ol {
    margin-left: 15px;
    list-style: decimal; }

#panel-notifications .notifies .notify {
  display: flex;
  margin-top: 55px;
  font-size: 14px; }
  #panel-notifications .notifies .notify__date {
    flex: 1;
    color: #7b7b7b; }
  #panel-notifications .notifies .notify__message {
    flex: 7; }

#panel-settings .form label {
  font-weight: bold; }

#panel-settings .form .form-group {
  margin-top: 60px; }
  #panel-settings .form .form-group input:last-child {
    margin-top: 20px; }

#panel-settings .form .checklist label {
  font-weight: normal; }

#panel-settings .form .button {
  margin-top: 40px;
  padding: 10px 0; }

#panel-prices .price-list {
  max-width: 700px; }
  #panel-prices .price-list__element {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: auto 100px 100px;
    grid-column-gap: 20px; }
    #panel-prices .price-list__element input {
      border: 1px solid #cccccc;
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
      font-size: 18px;
      padding: 12px 15px;
      width: 100%; }
    #panel-prices .price-list__element:first-child {
      margin-bottom: 0; }
      #panel-prices .price-list__element:first-child input {
        background: none;
        color: black;
        border: none;
        box-shadow: none; }

#panel-prices .button-black {
  margin: 20px 0;
  position: relative;
  padding: 10px 0 10px 20px;
  max-width: 260px; }
  #panel-prices .button-black i {
    position: absolute;
    left: 15px; }

#panel-prices .button-blue {
  margin-top: 100px; }

#panel-hours .hours-table {
  margin-top: 40px;
  margin-left: -10px; }
  @media only screen and (max-width: 767px) {
    #panel-hours .hours-table tr {
      border-top: 1px solid #ccc; } }
  #panel-hours .hours-table tr td {
    vertical-align: middle;
    padding: 10px;
    font-size: 18px; }
    #panel-hours .hours-table tr td input {
      margin: 0 10px;
      max-width: 80px;
      font-size: 18px;
      border: none;
      border-bottom: 1px solid #ccc; }
      #panel-hours .hours-table tr td input:focus {
        border-bottom-color: #2196F3; }
    #panel-hours .hours-table tr td:last-child {
      display: none; }

#panel-hours .button {
  margin-top: 40px;
  padding: 10px;
  max-width: 160px;
  font-size: 16px; }

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px; }

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

input:checked + .slider {
  background-color: #2196F3; }

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3; }

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px); }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

#panel-specs .specs-list {
  margin: 40px 0; }
  #panel-specs .specs-list li {
    font-size: 20px;
    margin: 10px 0; }
  #panel-specs .specs-list a {
    font-size: 14px;
    color: #f53737; }
    #panel-specs .specs-list a:hover {
      color: #17181c; }

#panel-specs .form {
  margin-top: 15px; }
  #panel-specs .form input, #panel-specs .form button {
    font-size: 16px;
    padding: 15px; }
